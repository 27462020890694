import {Link} from 'react-router-dom';

import DashboardItem from './sidebarditems/dashboardItem';
import ChatItem from "./sidebarditems/chatItem";
import AboutItem from "./sidebarditems/aboutItem";
import UserListItem from "./sidebarditems/userListItem";
import ExerciseListItem from "./sidebarditems/exerciseListItem";
import {logoIcon} from "../utils/svgs";
import CourseItem from "./sidebarditems/courseItem";
import MessageItem from "./sidebarditems/messageItem"
import ChatWizardTypeItem from './sidebarditems/ChatWizardTypeItem';
import AdminDashboardItem from './sidebarditems/adminDashboardItem';

const AppSidebar = (props) => {
    const user = props.user;
    const course = props.course;
    return (
        <div className="sidebar print:hidden">
            <div className="main-sidebar">
                <div
                    className="flex h-full w-full flex-col items-center border-r border-slate-150 bg-white dark:border-navy-700 dark:bg-navy-800"
                >
                    <div className="flex pt-4">
                        <Link to="/">
                            {logoIcon("h-11 w-11", "0 0 550 550")}
                        </Link>
                    </div>
                    {user?.user_role === 'admin' ? <AdminDashboardItem/> : <></>}
                    {user?.user_role !== 'admin' ? <DashboardItem/> : <></>}
                    {course?.role === 'teacher' ?
                        <CourseItem/>
                        :
                        <></>
                    }
                    {course && course.status === 'subscribed'?
                        <ChatItem/>
                        : <></>
                    }
                    {course?.role === 'teacher' ?
                        <UserListItem/>
                        :
                        <></>
                    }
                    {course?.role === 'teacher' ?
                        <ExerciseListItem/>
                        :
                        <></>
                    }
                    {course && course.status === 'subscribed' ?
                        <MessageItem data={props}/>
                        :
                        <></>
                    }
                    {user && user?.user_role === 'admin' ?
                        <ChatWizardTypeItem data={props}/>
                        :
                        <></>
                    }
                    <AboutItem/>
                </div>
            </div>
        </div>);
}

export default AppSidebar;