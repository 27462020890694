import {Outlet, useLocation} from "react-router-dom";
import SideBar from "./sidebar";
import AppPreloader from './preloader'
import Header from './header'
import React, {useEffect, useLayoutEffect} from "react";
import Application from "../app";
import {useLayoutContext} from "./layoutProvider";
import ReactGA, {event} from 'react-ga';
import loadHotjar from "../utils/hotgarTracker";
import CustomModal from '../components/customMdal';
import {Steps} from 'intro.js-react';
import 'intro.js/introjs.css';
import {useState} from 'react';

const Layout = (props) => {
    const outletObject = {user: props.user, course: props.course};
    const {loader} = useLayoutContext();
    const location = useLocation();
    const [showTour, setShowTour] = useState(false);
    const [showToureModal, setShowTourModal] = useState(false);
    const [introActive, setIntroActive] = useState(false);
    const [show, setShow] = useState(false);
    const user = outletObject.user;

    useEffect(() => {
        const seen = localStorage.getItem('seen_tour');
        if (seen === null && outletObject.course) {
            setShowTourModal(true);
        }
        ;
        if (seen === null && outletObject.user?.user_role === "admin") {
            setShowTourModal(true);
        }
    }, []);
    useLayoutEffect(() => {

        if (introActive) {
            setShow(true)
        }

    }, [introActive])
    const stepsForTeacher = [
        {
            element: '.header-plus-course',
            intro: 'This section allows you to create or join a course',
        },
        {
            element: '.join-course-popper',
            intro: 'Join an existing course using its code here',

        },
        {
            element: '.create-course-popper',
            intro: 'Create your own course here.',

        },
        {
            element: '.header-select-course',
            intro: 'Select a course to begin your journey',
        },
        {
            element: '.course',
            intro: "On this page, you can view your course's overall data and edit its details",
        },
        {
            element: '.chat',
            intro: 'Access the chat page here. You can have general conversations or discuss ideas about a specific exercise with our enhanced GPT-powered AI',
        }, {
            element: '.user-list',
            intro: 'Access the users page using this button, Here, you can view your students, manage their roles and assign exercises',
        }, {
            element: '.exercise',
            intro: 'This is the exercise page, where you can publish exercises for your students. ',
        },
    ];
    const stepsForStudent = [
        {
            element: '.header-plus-course',
            intro: 'This section allows you to create or join a course.',

        },
        {
            element: '.join-course-popper',
            intro: 'Join an existing course using its code here.',
            // highlightClass: 'custom-introjs-highlight',

        },
        {
            element: '.request-course-popper',
            intro: "Create your own course here."

        },


        {
            element: '.header-select-course',
            intro: 'Select a course to begin your journey',
        },
        {
            element: '.chat',
            intro: "Access the chat page here. You can have general conversations or discuss ideas about a specific exercise with our enhanced GPT-powered AI",
        },

    ]
    const stepsForNotAcceptedUser = [
        {
            element: '.header-plus-course',
            intro: 'This section allows you to create or join a course.',

        },
        {
            element: '.join-course-popper',
            intro: 'Join an existing course using its code here.',
        },

    ]
    const handleStartTour = () => {
        setShowTour(true);
        setShowTourModal(false);
        localStorage.setItem('seen_tour', true);
        setIntroActive(false);

    };

    const handleSkipTour = () => {
        setShowTourModal(false);
        setShowTour(false);
        setIntroActive(false);

        if (outletObject.course || outletObject.user?.user_role === "admin") {
            localStorage.setItem('seen_tour', true);
        }
    };
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
        window.dataLayer.push({
            event: 'pageview',
            page: {
                url: location,
            }
        });
    }, [location]);
    useEffect(() => {
        loadHotjar();
    }, []);
    useEffect(() => {
        window.App = new Application();
        window.dispatchEvent(new CustomEvent("app:mounted"));
    }, []);

    return (
        <div>
            {showTour &&
                <Steps
                    enabled
                    options={{doneLabel: "Done !"}}
                    steps={outletObject.course && outletObject.user?.user_role === "student" ? stepsForStudent :
                        outletObject.course && outletObject.user?.user_role === "teacher" ? stepsForTeacher : stepsForNotAcceptedUser}
                    initialStep={0}
                    onChange={(presentStep) => {
                        if (presentStep === 0) {
                            setIntroActive(true);
                        }
                    }
                    }
                    onExit={() => handleSkipTour()}


                />

            }
            <ul className={`p-ul w-70 z-50 ${introActive ? "" : "hidden"}`}>
                <li className="join-course-popper">
                    <a href="#"
                       className="flex  h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-slate-500 dark:text-navy-100"
                            fill="currentColor"
                            viewBox="0 0 340 340"
                        >
                            <g>
                                <path d="M280.988,0.001h-86.496c-4.136,0-7.5,3.364-7.5,7.5v20c0,4.135,3.364,7.5,7.5,7.5h58.996v218.487H35
                                  V35h58.996c4.136,0,7.5-3.364,7.5-7.5v-20c0-4.135-3.364-7.5-7.5-7.5H7.5C3.365,0,0,3.365,0,7.5v273.488c0,4.135,3.365,7.5,7.5,7.5
                                  h273.488c4.135,0,7.5-3.365,7.5-7.5V7.501C288.488,3.365,285.124,0.001,280.988,0.001z"/>
                                <path d="M87.489,97.032c-2.003,0-3.887,0.78-5.303,2.197l-14.143,14.142c-1.416,1.416-2.197,3.3-2.197,5.303
                                  c0,2.003,0.78,3.886,2.197,5.304l70.898,70.899c1.417,1.416,3.3,2.196,5.303,2.196s3.886-0.78,5.303-2.196l70.898-70.898
                                  c1.416-1.417,2.197-3.3,2.197-5.303c0-2.004-0.78-3.887-2.197-5.304l-14.143-14.142c-1.416-1.416-3.3-2.196-5.303-2.196
                                  c-2.003,0-3.887,0.78-5.303,2.197l-33.952,33.952V25.43c0-4.136-3.364-7.5-7.5-7.5h-20c-4.136,0-7.5,3.364-7.5,7.5v107.751
                                  L92.793,99.229C91.376,97.813,89.493,97.032,87.489,97.032z"/>
                            </g>
                        </svg>
                        <span>Join a Course</span>
                    </a>
                </li>
                {user?.user_role === "student" || user?.user_role === "human_teacher_assistant" ?
                    <li className={`request-course-popper ${(user != null && user !== undefined && (user.user_role === "student" || user.user_role === 'teacher' || user.user_role === 'admin')) ? '' : 'hidden'}`}>
                        <a href="#"
                           className="flex h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 text-slate-500 dark:text-navy-100"
                                fill="currentColor"
                                viewBox="0 0 22 22"
                            >
                                <g>
                                    <path
                                        d="M21 14v5c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2V5c0-1.105.895-2 2-2h5v2H5v14h14v-5h2z"/>
                                    <path d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4"/>
                                </g>
                            </svg>
                            <span>Request to Create a Course</span>
                        </a>
                    </li>

                    :
                    <li className={`create-course-popper ${(user != null && user !== undefined && (user.user_role === 'teacher' || user.user_role === 'admin')) ? '' : 'hidden'}`}>
                        <a href="#"
                           className="flex h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 text-slate-500 dark:text-navy-100"
                                fill="currentColor"
                                viewBox="0 0 22 22"
                            >
                                <g>
                                    <path
                                        d="M21 14v5c0 1.105-.895 2-2 2H5c-1.105 0-2-.895-2-2V5c0-1.105.895-2 2-2h5v2H5v14h14v-5h2z"/>
                                    <path d="M21 7h-4V3h-2v4h-4v2h4v4h2V9h4"/>
                                </g>
                            </svg>
                            <span>Create a Course</span>
                        </a>
                    </li>}
            </ul>

            {loader ? <AppPreloader/> : <></>}
            <SideBar user={outletObject.user} course={outletObject.course}/>
            <Header user={outletObject.user} course={outletObject.course}/>
            <Outlet context={outletObject}/>
            <CustomModal openModal={showToureModal} onClose={() => setShowTourModal(false)}>

                <div
                    className="flex justify-between bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5"
                >
                    <h3 className="text-base font-bold text-slate-700 dark:text-navy-100">
                        Welcom to JOURNiCRAFT !
                    </h3>
                    <svg className='cursor-pointer' onClick={() => handleSkipTour()} viewBox="0 -0.5 25 25"
                         width={20}
                         height={20} fill="#000000" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path
                                d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z"
                                fill="#000000"></path>
                        </g>
                    </svg>

                </div>
                <h4 className="px-4 pt-4 text-base font-semibold text-slate-700 dark:text-navy-100 dark:bg-navy-800 ">
                    Would you like to take a guided tour?
                </h4>
                <div className="space-x-2 dark:bg-navy-800  text-right flex justify-between p-6">
                    <button
                        onClick={(event) => handleStartTour()}
                        className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"

                    >
                        Yes
                    </button>
                    <button
                        onClick={() => handleSkipTour()}
                        className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"

                    >
                        No
                    </button>
                </div>

            </CustomModal>
        </div>
    );
}

export default Layout;