import React, {useEffect, useState} from "react";
import * as yup from 'yup';
import {useLayoutContext} from "../../layout/layoutProvider";
import {useLocation, useOutletContext, useNavigate} from "react-router-dom";
import FetchClient from "../../utils/fetchClient";
import Dialog from '@mui/material/Dialog';
import Select from 'react-select';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { CustomStyles, Option } from "../../components/selectOptions";

const ChatWizardType = () => {
    const location = useLocation()
    const {setLoader, setSidebarSelectedMenuItem} = useLayoutContext();
    const [firstAssistant, setFirstAssistant] = useState({});
    const [assistantsList, setAssistantsList] = useState([]);
    const [wizardTypesList, setWizardTypesList] = useState([]);
    const [wizardTypeModal, setWizardTypeModal] = useState(false);
    const [addModal, setAddModal] = useState("");
    const [question, setQuestion] = useState("");
    const [system, setSystem] = useState("");
    const [wizardType, setWizardType] = useState({
        id: -1, name: "", description: "", assistant: {id: 1},
        prompt: "", is_wizard: false, command: "", enabled_for_student: false
    });
    const [embededModelName, setEmbededModelName] = useState("");
    const [openEditWizardTypeModal, setOpenEditWizardTypeModal] = useState(false);
    const [errorsList, setErrorsList] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopUp, setOpenPopUp] = useState("");
    const navigate = useNavigate();
    const user = useOutletContext().user;
    const course = useOutletContext().course;
    const handleAddWizardType = () => {
        setWizardTypeModal(true);
        setWizardType(wizardType => ({
            ...wizardType,
            assistant: firstAssistant
        }));
    }
    const handleSelectWizard = (item) => {
        setOpenEditWizardTypeModal(true);
        const chosenAssistant = assistantsList.filter((assistant) => assistant.id === item.assistant_id)[0]
        setWizardType({
            name: item?.name, description: item?.description, prompt: item?.prompt,
            assistant: chosenAssistant, is_wizard: item?.is_wizard, id: item?.id, command: item?.command
        });
    };
    const handleAdd = (e, item) => {
        setAnchorEl(e.currentTarget);
        setOpenPopUp((prev) => !prev);
        setWizardType(item);
    }
    const wizardTypeTable = wizardTypesList?.length > 0 && wizardTypesList.map((wizardType, index) => (
        <tr key={"question_" + wizardType.id}
            className="border-y border-transparent cursor-pointer border-b-slate-200 dark:border-b-navy-500">
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{index + 1}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5 font-medium text-slate-700 dark:text-navy-100">{wizardType.name}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{wizardType.description}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{wizardType.prompt}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{wizardType.assistant_id}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{wizardType.is_wizard ? "Yes" : "No"}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5">{wizardType.enabled_for_student ? "Yes" : "No"}</td>
            <td className="whitespace-nowrap px-4 py-3 sm:px-5 flex">
                <svg onClick={() => handleSelectWizard(wizardType)} width="22px" height="22px" viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0">
                    </g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round">
                    </g>
                    <g id="SVGRepo_iconCarrier">
                        <path d="M13 21H21" stroke="#ffa408"
                              strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path
                            d="M20.0651 7.39423L7.09967 20.4114C6.72438 20.7882 6.21446 21 5.68265 21H4.00383C3.44943 21 3 20.5466 3 19.9922V18.2987C3 17.7696 3.20962 17.2621 3.58297 16.8873L16.5517 3.86681C19.5632 1.34721 22.5747 4.87462 20.0651 7.39423Z"
                            stroke="#ffa408" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M15.3096 5.30981L18.7273 8.72755" stroke="#ffa408" strokeWidth="2"
                              strokeLinecap="round" strokeLinejoin="round"></path>
                        <path opacity="0.1"
                              d="M18.556 8.90942L7.09967 20.4114C6.72438 20.7882 6.21446 21 5.68265 21H4.00383C3.44943 21 3 20.5466 3 19.9922V18.2987C3 17.7696 3.20962 17.2621 3.58297 16.8873L15.0647 5.35974C15.0742 5.4062 15.0969 5.45049 15.1329 5.48653L18.5506 8.90426C18.5524 8.90601 18.5542 8.90773 18.556 8.90942Z"
                              fill="#ffa408"></path>
                    </g>
                </svg>
                {wizardType?.is_wizard &&
                    <>
                        <svg onClick={(e) => handleAdd(e, wizardType)} width="22px" height="22px" viewBox="0 0 24 24"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <circle cx="12" cy="12" r="10" stroke="#087d2d" strokeWidth="1.5"></circle>
                                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#087d2d"
                                      strokeWidth="1.5" strokeLinecap="round"></path>
                            </g>
                        </svg>
                        <svg onClick={() => navigate(`/chat/wizard/type/${wizardType.id}/questions`)} width="24px"
                             height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#5757e7">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path
                                    d="M12.5 12.793l2.847 2.847-.707.707-1.64-1.64V22h-1v-7.293l-1.64 1.64-.707-.707zm0-1.793A1.5 1.5 0 1 1 14 9.5a1.5 1.5 0 0 1-1.5 1.5zm0-1a.5.5 0 1 0-.5-.5.5.5 0 0 0 .5.5zm0-7.8a7.289 7.289 0 0 0-5.302 12.302l.707-.706a6.3 6.3 0 1 1 9.273-.092l.707.707A7.287 7.287 0 0 0 12.5 2.201z"></path>
                                <path fill="none" d="M0 0h24v24H0z"></path>
                            </g>
                        </svg>
                        <svg onClick={() => navigate(`/chat/wizard/type/${wizardType?.id}/embedded-models`)}
                             width="22px" height="22px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"
                             fill="none" stroke="#000000">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <rect x="8" y="40" width="16" height="16"></rect>
                                <rect x="40" y="40" width="16" height="16"></rect>
                                <rect x="24" y="8" width="16" height="16"></rect>
                                <polyline points="48 40 48 32 16 32 16 40"></polyline>
                                <line x1="32" y1="32" x2="32" y2="24"></line>
                            </g>
                        </svg>
                    </>
                }
            </td>
        </tr>)
    );
    const keyupHandler = (event) => {
        if (event.key === 'Enter') {
            postWizardType();
        }
        return true;
    };
    const keyupEditHandler = (event) => {
        if (event.key === 'Enter') {
            editWizardType();
        }
        return true;
    };
    const keyupAddQuestionHandler = (event) => {
        if (event.key === 'Enter') {
            addQuestion();
        }
        return true;
    };
    const keyupAddModelHandler = (event) => {
        if (event.key === 'Enter') {
            addModel();
        }
    }
    const getWizardTypesList = () => {
        FetchClient({
            method: 'get',
            url: `chat/wizard/types`,
            params: {
                enabled_for_student: false
            }
        }).then(response => {
            setWizardTypesList(response?.data?.items)
        })
    }

    const postWizardType = async () => {
        const schema = yup.object().shape({
            wizardType: yup.object().shape({
                name: yup.string().required("Name of wizard is required !"),
                description: yup.string().required("Description of wizard is required !"),
                prompt: yup.string().required("Prompt is required !"),
                command: yup.string().when("is_wizard", {
                    is: (val) => val === true,
                    then: (schema) => schema.required("Command is required !"),
                }),
                assistant: yup.object().shape({
                    id: yup.number().required('Assistant ID is required !'),
                    name: yup.string().required('Assistant name is required !')
                }),
                enabled_for_student: yup.boolean().required('Enabled_for_student is required!'),
            })
        });
        try {
            await schema.validate({wizardType}, {abortEarly: false});
            setLoader(true);
            FetchClient({
                method: 'post',
                url: 'admin/chat/wizard/type/create',
                data: {
                    name: wizardType.name,
                    description: wizardType.description,
                    assistant_id: wizardType.assistant?.id,
                    prompt: wizardType.prompt,
                    is_wizard: wizardType.is_wizard,
                    command: wizardType?.command,
                    enabled_for_student: wizardType?.enabled_for_student

                }
            }).then(response => {
                setWizardType({
                    name: "", description: "", assistant: {id: 1},
                    prompt: "", is_wizard: false, command: ""
                });
                setLoader(false);
                getWizardTypesList();
                setWizardTypeModal(false);
                setErrorsList([]);
                window.$notification({
                    text: 'Your wizard is created !',
                    variant: 'success',
                    position: 'center-top'
                })
            }).catch((error) => {
                window.$notification({
                    text: 'Oops! There was an error',
                    variant: 'error',
                    position: 'center-top'
                });
            })
        } catch (err) {
            setErrorsList(err.inner);
        }
    };

    const editWizardType = async () => {
        const schema = yup.object().shape({
            wizardType: yup.object().shape({
                name: yup.string().required("Name of wizard is required !"),
                description: yup.string().required("Description of wizard is required !"),
                prompt: yup.string().required("Prompt is required !"),
                command: yup.string().when("is_wizard", {
                    is: (val) => val === true,
                    then: (schema) => schema.required("Command is required !"),
                }),
                assistant: yup.object().shape({
                    id: yup.number().required('Assistant ID is required'),
                    name: yup.string().required('Assistant name is required')
                })
            }),
        });
        try {
            await schema.validate({wizardType}, {abortEarly: false});
            setLoader(true);
            debugger
            FetchClient({
                method: 'post',
                url: 'admin/chat/wizard/type/update',
                data: {
                    name: wizardType.name,
                    description: wizardType.description,
                    assistant_id: wizardType.assistant?.id,
                    prompt: wizardType.prompt,
                    is_wizard: wizardType.is_wizard,
                    id: wizardType?.id,
                    command: wizardType?.command,
                    enabled_for_student: wizardType?.enabled_for_student,
                    is_deleted: false

                }
            }).then(response => {
                setWizardType({
                    name: "", description: "", assistant: {id: 1},
                    prompt: "", is_wizard: false, command: ""
                });
                setLoader(false);
                getWizardTypesList();
                setOpenEditWizardTypeModal(false);
                setErrorsList([]);
                window.$notification({
                    text: 'Your wizard is updated !',
                    variant: 'success',
                    position: 'center-top'
                })
            }).catch((error) => {
                window.$notification({
                    text: 'Oops! There was an error',
                    variant: 'error',
                    position: 'center-top'
                });
            })
        } catch (err) {
            setErrorsList(err.inner);
        }
    };
    const addQuestion = async () => {
        const schema = yup.object().shape({
            question: yup.string().required("Question is required !"),
            system: yup.string().required("System is required !"),
        });
        try {
            await schema.validate({question, system}, {abortEarly: false});
            setLoader(true);
            FetchClient({
                method: 'post',
                url: 'admin/chat/wizard/question/create',
                data: {
                    question: question,
                    system: system,
                    chat_type_id: wizardType.id
                }
            }).then(response => {
                setWizardType({
                    name: "", description: "", assistant: {id: 1},
                    prompt: "", is_wizard: false, id: -1, command: ""
                });
                setSystem("");
                setAddModal("");
                getWizardTypesList();
                setOpenPopUp(false);
                setLoader(false);
                setQuestion("");
                setErrorsList([]);
                window.$notification({
                    text: 'Your question is added !',
                    variant: 'success',
                    position: 'center-top'
                })
            }).catch((error) => {
                window.$notification({
                    text: 'Oops! There was an error',
                    variant: 'error',
                    position: 'center-top'
                });
            })
        } catch (err) {
            setErrorsList(err.inner);
        }

    };
    const addModel = async () => {
        const schema = yup.object().shape({
            embededModelName: yup.string().required("Name is required !"),
        });
        try {
            await schema.validate({embededModelName}, {abortEarly: false});
            setLoader(true);
            setAddModal("");
            FetchClient({
                method: 'post',
                url: 'admin/chat/wizard/embedding/model/create',
                data: {
                    owner: "journicraft",
                    name: embededModelName,
                    chat_type_id: wizardType?.id,
                    course_id: null
                }
            }).then(response => {
                setWizardType({
                    name: "", description: "", assistant: {id: 1},
                    prompt: "", is_wizard: false, id: -1, command: ""
                });
                setEmbededModelName("");
                setAddModal("");
                setLoader(false);
                setErrorsList([]);
                getWizardTypesList();
                setOpenPopUp(false);
                setOpenPopUp(false);
                window.$notification({
                    text: 'Your Model is added !',
                    variant: 'success',
                    position: 'center-top'
                })
            }).catch((error) => {
                window.$notification({
                    text: 'Oops! There was an error',
                    variant: 'error',
                    position: 'center-top'
                });
            })
        } catch (err) {
            setErrorsList(err.inner);
        }

    };
    const handleCloseWizardTypeModal = () => {
        setWizardTypeModal(false);
        setErrorsList([]);
        setWizardType({
            name: "", description: "", assistant: {id: 1},
            prompt: "", is_wizard: false, id: -1, command: ""
        })
    }
    const handleCloseEditWizardTypeModal = () => {
        setOpenEditWizardTypeModal(false);
        setErrorsList([]);
        setWizardType({
            name: "", description: "", assistant: {id: 1},
            prompt: "", is_wizard: false, id: -1, command: ""
        })
    }

    function updateMenu() {
        if (location.pathname.includes('chat/wizard/type')) {
            setSidebarSelectedMenuItem('chat_wizard_type');
        }
    };
    const getAssistant = () => (
        FetchClient({
            method: 'get',
            url: '/assistants'
        }).then(response => {
            setAssistantsList(response.data);
            setWizardType(wizardType => ({
                ...wizardType,
                assistant: response?.data[0]
            }));
            setFirstAssistant(response?.data[0]);
        })
    );
    useEffect(() => {
        updateMenu();
        getAssistant();
        getWizardTypesList();
    }, [location]);
    const handleSelectAssistant = (selected, index) => {
        setWizardType(wizardType => ({
            ...wizardType,
            assistant: selected
        }))
    }
    const handleCloseAddModal = () => {
        setAddModal("");
        setWizardType({
            id: -1, name: "", description: "", assistant: {id: 1},
            prompt: "", is_wizard: false, command: ""
        });
        setQuestion("");
        setErrorsList([]);
    }
    return (
        <main className="main-content h-100vh mt-0">
            <div className="scrollbar-sm grow px-[calc(var(--margin-x)-.5rem)] mt-20 transition-all duration-[.25s]">
                <div className={(user && user.user_role === 'admin' ? ' ' : ' hidden')}>
                    <button
                        class="btn ml-2 space-x-2 border font-medium text-white bg-primary/90 dark:text-white dark:bg-primary/50"
                        onClick={(event) => handleAddWizardType(event)}>
                        <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <circle cx="12" cy="12" r="10" stroke="#ffffff" strokeWidth="1.5"></circle>
                                <path d="M15 12L12 12M12 12L9 12M12 12L12 9M12 12L12 15" stroke="#ffffff"
                                      strokeWidth="1.5" strokeLinecap="round"></path>
                            </g>
                        </svg>
                        <span>Create type</span>
                    </button>
                </div>
            </div>
            <div
                className="scrollbar-sm grow px-[calc(var(--margin-x)-.5rem)] py-2 mt-2 transition-all duration-[.25s]">
                    <div className="card mt-3">
                        <div className="overflow-x-auto">
                            <table className="is-hoverable w-full text-left">
                                <thead>
                                <tr>
                                    <th className="whitespace-nowrap rounded-tl-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                        #
                                    </th>
                                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                        Name
                                    </th>
                                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                        Description
                                    </th>
                                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                        Prompt
                                    </th>
                                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                        Assistant id
                                    </th>
                                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                        Wizard
                                    </th>
                                    <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                        Student
                                    </th>
                                    <th className="whitespace-nowrap rounded-tr-lg bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                                        Action
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {wizardTypesList.length > 0 && wizardTypeTable}
                                </tbody>
                            </table>
                        </div>
                    </div>
            </div>

            <Dialog
                open={wizardTypeModal}
                sx={{overflow: "visible", zIndex: 30}}
                onClose={() => handleCloseWizardTypeModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className="flex justify-between bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                    <h1 className="text-base font-medium text-slate-700 dark:text-navy-100">Create wizard </h1>
                    <button
                        onClick={() => handleCloseWizardTypeModal()}
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div
                    className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden bg-white dark:bg-navy-700">
                    <form onKeyUp={(e) => keyupHandler(e)} className="scrollbar-sm text-navy-600 dark:text-navy-100 overflow-y-auto px-4 py-4 sm:px-5">
                        <div className="mt-4 space-y-4">
                            <label className="block">
                                <span>Name:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    name="name"
                                    value={wizardType.name}
                                    onChange={(e) => setWizardType(wizardType => ({
                                        ...wizardType,
                                        name: e.target.value
                                    }))}
                                    type={"text"}
                                />
                                <span
                                    className="text-error-focus">{errorsList.filter((name) => name.path === "wizardType.name")?.[0]?.errors?.[0]}</span>

                            </label>
                            <label className="block">
                                <span>Description:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    name="course_description"
                                    value={wizardType.description}
                                    onChange={(e) => setWizardType(wizardType => ({
                                        ...wizardType,
                                        description: e.target.value
                                    }))}
                                    type={"text"}
                                />
                                <span
                                    className="text-error-focus">{errorsList.filter((name) => name.path === "wizardType.description")?.[0]?.errors?.[0]}</span>

                            </label>
                            {wizardType.is_wizard ?
                                <label className="block">
                                    <span>Command:</span>
                                    <input
                                        className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                        name="course_description"
                                        value={wizardType.command}
                                        onChange={(e) => setWizardType(wizardType => ({
                                            ...wizardType,
                                            command: e.target.value
                                        }))}
                                        type={"text"}
                                    />
                                    <span
                                        className="text-error-focus">{errorsList.filter((name) => name.path === "wizardType.command")?.[0]?.errors?.[0]}</span>

                                </label> : ""}
                            <label className="block">
                                <span>Prompt:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    name="teacher_description"
                                    value={wizardType.prompt}
                                    onChange={(e) => setWizardType(wizardType => ({
                                        ...wizardType,
                                        prompt: e.target.value
                                    }))}
                                    type={"text"}
                                />
                                <span
                                    className="text-error-focus">{errorsList.filter((name) => name.path === "wizardType.prompt")?.[0]?.errors?.[0]}</span>
                            </label>
                            <label className="block">
                                <span>Assistant:</span>
                                <div className="h-full  rounded-xl flex flex-col">
                                    <Select
                                        styles={CustomStyles}
                                        loadingMessage={() => 'Loading ...'}
                                        closeMenuOnSelect={true}
                                        value={wizardType.assistant}
                                        isMulti={false}
                                        components={{Option}}
                                        menuPortalTarget={document.body}
                                        options={assistantsList}
                                        getOptionLabel={(option) => option?.name}
                                        getOptionValue={(option) => option?.id}
                                        onChange={handleSelectAssistant}
                                    />
                                </div>
                                <span
                                    className="text-error-focus">{errorsList.filter((name) => name.path === "wizardType.assistant.name")?.[0]?.errors?.[0]}</span>
                            </label>
                            <label className="flex p-4">
                                <span>Is this wizard type?</span>
                                <input
                                    className="form-checkboxis-outline ml-2.5 h-5 w-5 border-slate-400/70 bg-slate-100 before:bg-slate-500 checked:border-slate-500 hover:border-slate-500 focus:border-slate-500 dark:border-navy-500 dark:bg-navy-900 dark:before:bg-navy-200 dark:checked:border-navy-200 dark:hover:border-navy-200 dark:focus:border-navy-200"
                                    type="checkbox"
                                    onChange={(e) => setWizardType(wizardType => ({
                                        ...wizardType,
                                        is_wizard: e.target.checked
                                    }))}
                                />
                            </label>
                            <label className="flex p-4">
                                <span>Is this enabled for student?</span>
                                <input
                                    className="form-checkboxis-outline ml-2.5 h-5 w-5 border-slate-400/70 bg-slate-100 before:bg-slate-500 checked:border-slate-500 hover:border-slate-500 focus:border-slate-500 dark:border-navy-500 dark:bg-navy-900 dark:before:bg-navy-200 dark:checked:border-navy-200 dark:hover:border-navy-200 dark:focus:border-navy-200"
                                    type="checkbox"
                                    onChange={(e) => setWizardType(wizardType => ({
                                        ...wizardType,
                                        enabled_for_student: e.target.checked
                                    }))}
                                />
                            </label>
                        </div>
                    </form>
                </div>
                <div className="!text-right w-full px-4 py-4 bg-white dark:bg-navy-800">
                    <button
                        className="btn mx-4 min-w-[7rem] rounded-full border border-navy-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                        onClick={(event) => handleCloseWizardTypeModal()}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={postWizardType}
                        className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    >
                        Add
                    </button>
                </div>
            </Dialog>
            <Dialog
                open={openEditWizardTypeModal}
                onClose={() => handleCloseEditWizardTypeModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className="flex justify-between bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                    <h1 className="text-base font-medium text-slate-700 dark:text-navy-100">Edit wizard </h1>
                    <button
                        onClick={() => handleCloseEditWizardTypeModal()}
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div
                    className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden bg-white dark:bg-navy-700">
                    <form onKeyUp={(e) => keyupEditHandler(e)}
                          className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
                        <div className="mt-4 space-y-4">
                            <label className="block">
                                <span>Name:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    name="name"
                                    value={wizardType.name}
                                    onChange={(e) => setWizardType(wizardType => ({
                                        ...wizardType,
                                        name: e.target.value
                                    }))}
                                    type={"text"}
                                />
                                <span
                                    className="text-error-focus">{errorsList.filter((name) => name.path === "wizardType.name")?.[0]?.errors?.[0]}</span>

                            </label>
                            <label className="block">
                                <span>Description:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    name="course_description"
                                    value={wizardType.description}
                                    onChange={(e) => setWizardType(wizardType => ({
                                        ...wizardType,
                                        description: e.target.value
                                    }))}
                                    type={"text"}
                                />
                                <span
                                    className="text-error-focus">{errorsList.filter((name) => name.path === "wizardType.description")?.[0]?.errors?.[0]}</span>

                            </label>
                            <label className="block">
                                <span>Prompt:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    name="teacher_description"
                                    value={wizardType.prompt}
                                    onChange={(e) => setWizardType(wizardType => ({
                                        ...wizardType,
                                        prompt: e.target.value
                                    }))}
                                    type={"text"}
                                />
                                <span
                                    className="text-error-focus">{errorsList.filter((name) => name.path === "wizardType.prompt")?.[0]?.errors?.[0]}</span>
                            </label>
                            {wizardType?.is_wizard ?
                                <label className="block">
                                    <span>Command:</span>
                                    <input
                                        className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                        name="teacher_description"
                                        value={wizardType.command}
                                        onChange={(e) => setWizardType(wizardType => ({
                                            ...wizardType,
                                            command: e.target.value
                                        }))}
                                        type={"text"}
                                    />
                                    <span
                                        className="text-error-focus">{errorsList.filter((name) => name.path === "wizardType.command")?.[0]?.errors?.[0]}</span>
                                </label> : ""}
                            <label className="block">
                                <span>Assistant:</span>
                                <Select
                                    styles={CustomStyles}
                                    loadingMessage={() => 'Loading ...'}
                                    closeMenuOnSelect={true}
                                    value={wizardType.assistant}
                                    isMulti={false}
                                    menuPortalTarget={document.body}
                                    options={assistantsList}
                                    getOptionLabel={(option) => option?.name}
                                    getOptionValue={(option) => option?.id}
                                    onChange={handleSelectAssistant}
                                />
                                <span
                                    className="text-error-focus">{errorsList.filter((name) => name.path === "wizardType.assistant.name")?.[0]?.errors?.[0]}</span>
                            </label>
                            <label className="flex p-4">
                                <span>Is this wizard type?</span>
                                <input
                                    className="form-checkboxis-outline ml-2.5 h-5 w-5 border-slate-400/70 bg-slate-100 before:bg-slate-500 checked:border-slate-500 hover:border-slate-500 focus:border-slate-500 dark:border-navy-500 dark:bg-navy-900 dark:before:bg-navy-200 dark:checked:border-navy-200 dark:hover:border-navy-200 dark:focus:border-navy-200"
                                    type="checkbox"
                                    checked={wizardType.is_wizard}
                                    onChange={(e) => setWizardType(wizardType => ({
                                        ...wizardType,
                                        is_wizard: e.target.checked
                                    }))}
                                />
                            </label>
                            <label className="flex p-4">
                                <span>Is this enabled for student?</span>
                                <input
                                    className="form-checkboxis-outline ml-2.5 h-5 w-5 border-slate-400/70 bg-slate-100 before:bg-slate-500 checked:border-slate-500 hover:border-slate-500 focus:border-slate-500 dark:border-navy-500 dark:bg-navy-900 dark:before:bg-navy-200 dark:checked:border-navy-200 dark:hover:border-navy-200 dark:focus:border-navy-200"
                                    type="checkbox"
                                    checked={wizardType.enabled_for_student}
                                    onChange={(e) => setWizardType(wizardType => ({
                                        ...wizardType,
                                        enabled_for_student: e.target.checked
                                    }))}
                                />
                            </label>

                        </div>
                    </form>
                </div>
                <div className="!text-right w-full px-4 py-4 bg-white dark:bg-navy-800">
                    <button
                        className="btn mx-4 min-w-[7rem] rounded-full border border-navy-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                        onClick={(event) => handleCloseEditWizardTypeModal()}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={editWizardType}
                        className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    >
                        Edit
                    </button>
                </div>
            </Dialog>
            <Dialog
                open={addModal === "add-question"}
                onClose={() => handleCloseAddModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className="flex justify-between bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                    <h1 className="text-base font-medium text-slate-700 dark:text-navy-100">Add question </h1>
                    <button
                        onClick={() => handleCloseAddModal()}
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div
                    className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden bg-white dark:bg-navy-700">
                    <form onSubmit={(e) => e.preventDefault()} onKeyUp={(e) => keyupAddQuestionHandler(e)}
                          className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
                        <div className="mt-4 space-y-4">
                            <label className="block">
                                <span>Question:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    name="question"
                                    value={question}
                                    onChange={(e) => setQuestion(e.target.value)}
                                    type={"text"}
                                />
                                <span
                                    className="text-error-focus">{errorsList.filter((name) => name.path === "question")?.[0]?.errors?.[0]}</span>

                            </label>
                            <label className="block">
                                <span>System:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    name="system"
                                    value={system}
                                    onChange={(e) => setSystem(e.target.value)}
                                    type={"text"}
                                />
                                <span
                                    className="text-error-focus">{errorsList.filter((name) => name.path === "system")?.[0]?.errors?.[0]}</span>
                            </label>
                        </div>
                    </form>
                </div>
                <div className="!text-right w-full px-4 py-4 bg-white dark:bg-navy-800">
                    <button
                        className="btn mx-4 min-w-[7rem] rounded-full border border-navy-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                        onClick={(event) => handleCloseAddModal()}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={addQuestion}
                        className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    >
                        Add
                    </button>
                </div>
            </Dialog>
            <Dialog
                open={addModal === "add-model"}
                onClose={() => handleCloseAddModal()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                    <h1 className="text-base font-medium text-slate-700 dark:text-navy-100">Add model </h1>
                    <button
                        onClick={() => handleCloseAddModal()}
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <div
                    className="relative flex w-full max-w-lg origin-top flex-col overflow-hidden bg-white dark:bg-navy-700">
                    <form onSubmit={(e) => e.preventDefault()} onKeyUp={(e) => keyupAddModelHandler(e)}
                          className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
                        <div className="mt-4 space-y-4">
                            <label className="block">
                                <span>Name:</span>
                                <input
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    name="name"
                                    value={embededModelName}
                                    onChange={(e) => setEmbededModelName(e.target.value)}
                                    type={"text"}
                                />
                                <span
                                    className="text-error-focus">{errorsList.filter((name) => name.path === "embededModelName")?.[0]?.errors?.[0]}</span>

                            </label>
                        </div>
                    </form>
                </div>
                <div className="!text-right w-full px-4 py-4 bg-white dark:bg-navy-800">
                    <button
                        className="btn mx-4 min-w-[7rem] rounded-full border border-navy-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                        onClick={(event) => handleCloseAddModal()}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={addModel}
                        className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                    >
                        Add
                    </button>
                </div>
            </Dialog>
            <Popper
                className="!z-50 !w-60 "
                open={openPopUp}
                anchorEl={anchorEl}
                placement={"bottom"}
                transition>
                {({TransitionProps}) => (
                    <Fade
                        className="px-3 !shadow-none rounded-md border border-slate-150 bg-slate-100 text-slate-800 dark:bg-navy-600 dark:text-navy-100"
                        {...TransitionProps} timeout={350}>
                        <Paper>
                            <ul>
                                <li className='p-2 cursor-pointer' onClick={() => setAddModal("add-question")}
                                    key={"add-question"}> Add question
                                </li>
                                <li className='p-2 cursor-pointer' onClick={() => setAddModal("add-model")}
                                    key={"add-model"}> Add model
                                </li>
                            </ul>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </main>
    );
}

export default ChatWizardType;