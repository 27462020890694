import React, {useEffect, useState} from 'react';
import ReactHTMLParser, {domToReact} from 'html-react-parser';
import userIcon from '../../../../images/user-black.png';
import Modal from "../../../components/modal";
import Popper from "../../../components/popper";
import {useAuth} from "../../../utils/authProvider";
import ReactQuill from "react-quill";
import FetchClient from "../../../utils/fetchClient";
import {openCloseSidebarPanel} from "../../../utils/helpers";
import {useLocation, useNavigate} from "react-router-dom";
import Typewriter from '../../../components/typewriter';

const ChatMessage = (props) => {
    const location = useLocation()
    const navigate = useNavigate();
    const {stores} = useAuth();
    const [feedbackModal, setFeedbackModal] = useState();
    const [feedbackInputs, setFeedbackInputs] =
        useState({content: ""});

    const id = props.id;
    const loading = props.loading;
    const message_datetime = new Date(props.datetime);
    const chatId = props.chat_id;
    const lastItem = props.lastItem;
    const role = props.role;
    const type = props.type;
    const asssitant_image_url = props.asssitant_image_url;
    function bold(text) {
        var bold = /\*\*(.*?)\*\*/gm;
        var html = text?.replace(bold, '<strong>$1</strong>');
        return html;
    }
    const parsedContent =
        ReactHTMLParser(bold(props.content), {
            replace: ({tagName, children}) => {
                if (tagName === 'html') {
                    return domToReact(children);
                }
            }
        });

    useEffect(() => {
        setFeedbackInputs({content: feedbackInputs.content});
        if (stores?.user?.user_role === 'teacher' && stores?.course?.role === 'teacher') {
            setFeedbackModal(new Modal("#feedbackModal_" + id));
            new Popper("#chat-feedback-menu-" + id, ".popper-ref", ".popper-root", {
                placement: "bottom-end",
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 4],
                        },
                    },
                ],
            });
        }
    }, []);

    const openFeedbackModal = (event, this_id) => {
        event.preventDefault();
            FetchClient({
            method: 'get',
            url: `reply_message/` + this_id,
            params: {
                reply_to_message_id: this_id,
                type: 'feedback'
            }
        }).then(response => {
            openCloseSidebarPanel(false);
            let feedback = response.data;
            setFeedbackInputs({content: feedback.content});
            feedbackModal.open();
        }).catch((error) => {
            setFeedbackInputs({content: ""});
            feedbackModal.open();
        })
    }
    const handleFeedbackHTMLChange = (content, delta, source, editor) => {
        setFeedbackInputs({content: content});
    }
    const submitFeedback = (event) => {
        event.preventDefault();       
          FetchClient({
            method: 'post',
            url: `feedback`,
            data: {
                message: {
                    content: feedbackInputs.content,
                    role: 'admin',
                    chat_id: chatId,
                    type: 'feedback'
                },
                reply_to_message_id: id
            }
        }).then(response => {
            setFeedbackInputs({content: ""});
            feedbackModal.close();
            window.location.reload();
            // navigate(location, {replace: true});
        });
    }

    return <div id={"chat-feedback-menu-" + id}>
        {(role === 'user') ?
        <>
            <div className="flex items-start space-x-2.5 sm:space-x-5">
                <div className="popper-ref avatar">
                    <img
                        className="rounded-full"
                        src={userIcon}
                        alt="she"
                    />
                </div>
                <div className="flex flex-col items-start space-y-3.5">
                    <div className="mr-4 max-w-[80%] sm:mr-10">
                        <div
                            className="text-base rounded-2xl rounded-tl-none bg-teal-100 p-3 text-slate-700 shadow-sm dark:bg-blue-900 dark:text-slate-300"
                        >
                            {parsedContent}
                        </div>
                        <p
                            className="mt-1 ml-auto text-right text-xs text-slate-400 dark:text-navy-300"
                        >
                            {("0" + message_datetime.getHours()).slice(-2) + ":" +
                                ("0" + message_datetime.getMinutes()).slice(-2)}
                        </p>
                    </div>
                </div>
               
            </div>
            {loading[1] && id === loading[0] &&
                <div className="flex items-start justify-end space-x-2.5 sm:space-x-5">
                <div className="flex flex-col items-end space-y-3.5">
                <div className="ml-4 max-w-[80%] sm:ml-10">
                                <div
                                    className="item-center justify-center rounded-2xl rounded-tr-none bg-indigo-100 p-3 text-slate-700 shadow-sm dark:bg-navy-700 dark:text-navy-100">
                                    
                                    <svg className="object-cover"
                                         xmlns="http://www.w3.org/2000/svg"
                                         width="100px" height="30px"
                                         viewBox="0 0 90 90" preserveAspectRatio="xMidYMid">
                                        <circle cx="84" cy="50" r="10" fill="#e15b64">
                                            <animate attributeName="r" repeatCount="indefinite"
                                                     dur="0.33783783783783783s" calcMode="spline" keyTimes="0;1"
                                                     values="10;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
                                            <animate attributeName="fill" repeatCount="indefinite"
                                                     dur="1.3513513513513513s" calcMode="discrete"
                                                     keyTimes="0;0.25;0.5;0.75;1"
                                                     values="#e15b64;#abbd81;#f8b26a;#f47e60;#e15b64"
                                                     begin="0s"></animate>
                                        </circle>
                                        <circle cx="16" cy="50" r="10" fill="#e15b64">
                                            <animate attributeName="r" repeatCount="indefinite"
                                                     dur="1.3513513513513513s" calcMode="spline"
                                                     keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10"
                                                     keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                                                     begin="0s"></animate>
                                            <animate attributeName="cx" repeatCount="indefinite"
                                                     dur="1.3513513513513513s" calcMode="spline"
                                                     keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84"
                                                     keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                                                     begin="0s"></animate>
                                        </circle>
                                        <circle cx="50" cy="50" r="10" fill="#f47e60">
                                            <animate attributeName="r" repeatCount="indefinite"
                                                     dur="1.3513513513513513s" calcMode="spline"
                                                     keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10"
                                                     keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                                                     begin="-0.33783783783783783s"></animate>
                                            <animate attributeName="cx" repeatCount="indefinite"
                                                     dur="1.3513513513513513s" calcMode="spline"
                                                     keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84"
                                                     keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                                                     begin="-0.33783783783783783s"></animate>
                                        </circle>
                                        <circle cx="84" cy="50" r="10" fill="#f8b26a">
                                            <animate attributeName="r" repeatCount="indefinite"
                                                     dur="1.3513513513513513s" calcMode="spline"
                                                     keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10"
                                                     keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                                                     begin="-0.6756756756756757s"></animate>
                                            <animate attributeName="cx" repeatCount="indefinite"
                                                     dur="1.3513513513513513s" calcMode="spline"
                                                     keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84"
                                                     keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                                                     begin="-0.6756756756756757s"></animate>
                                        </circle>
                                        <circle cx="16" cy="50" r="10" fill="#abbd81">
                                            <animate attributeName="r" repeatCount="indefinite"
                                                     dur="1.3513513513513513s" calcMode="spline"
                                                     keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10"
                                                     keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                                                     begin="-1.0135135135135136s"></animate>
                                            <animate attributeName="cx" repeatCount="indefinite"
                                                     dur="1.3513513513513513s" calcMode="spline"
                                                     keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84"
                                                     keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
                                                     begin="-1.0135135135135136s"></animate>
                                        </circle>
                                    </svg>
                                </div>
                                <p
                                className="mt-1 ml-auto text-left text-xs text-slate-400 dark:text-navy-300"
                            >
                                {("0" + message_datetime.getHours()).slice(-2) + ":" +
                                    ("0" + message_datetime.getMinutes()).slice(-2)}
                            </p>
                </div>

                        </div>
                    
                    <div className="popper-ref avatar">
                        <img
                            className="rounded-full"
                            // src={process.env.PUBLIC_URL + '/images/assistant/assistant_' + assistant.id + '.jpg'}
                            src={asssitant_image_url}
                            alt={"me"}
                        />
                    </div>
                </div>
                }
                </>
            
            : (role === 'assistant' ) ?
                <div className="flex items-start justify-end space-x-2.5 sm:space-x-5">
                    <div className="flex flex-col items-end space-y-3.5">
                    <div className="ml-4 max-w-[80%] sm:ml-10">
                           
                                <div
                                    className="text-base rounded-2xl rounded-tr-none bg-indigo-100 p-3 text-slate-700 shadow-sm dark:bg-navy-700 dark:text-navy-100">
                                   {lastItem ? <Typewriter delay={40} text={props.content}/> 
                                   : parsedContent}
                                </div>
                            
                            <p
                                className="mt-1 ml-auto text-left text-xs text-slate-400 dark:text-navy-300"
                            >
                                {("0" + message_datetime.getHours()).slice(-2) + ":" +
                                    ("0" + message_datetime.getMinutes()).slice(-2)}
                            </p>
                        </div>
                    </div>
                    <div className="popper-ref avatar">
                        <img
                            className="rounded-full"
                            // src={process.env.PUBLIC_URL + '/images/assistant/assistant_' + assistant.id + '.jpg'}
                            src={asssitant_image_url}
                            alt={"me"}
                        />
                    </div>
                </div>
                :
                <div className="flex popper-ref items-start justify-center space-x-2.5 sm:space-x-5">
                    <div className="flex flex-col items-end space-y-3.5">
                        <div className="ml-4 sm:ml-10 sm:mr-10">
                            {type === 'summary' ?
                                (<div>
                                    <div
                                        className="text-base rounded-2xl bg-lime-100 p-3 text-slate-700 shadow-sm dark:bg-emerald-900 dark:text-navy-100">
                                        <h2>
                                            <strong>Summary:</strong>
                                        </h2>
                                        {parsedContent}
                                    </div>
                                </div>)
                                :
                                type === 'feedback' ? (<div>
                                        <div
                                            className="text-base rounded-2xl bg-pink-100 p-3 text-slate-700 shadow-sm dark:bg-pink-900 dark:text-navy-100">
                                            <h2>
                                                <strong>Feedback:</strong>
                                            </h2>
                                            {parsedContent}
                                        </div>
                                    </div>)
                                    :
                                    <div
                                        className="text-base rounded-2xl bg-white p-3 text-slate-700 shadow-sm dark:bg-navy-700 dark:text-navy-100">
                                        {parsedContent}
                                    </div>
                            }
                            <p
                                className="mt-1 ml-auto text-center text-xs text-slate-400 dark:text-navy-300"
                            >
                                {("0" + message_datetime.getHours()).slice(-2) + ":" +
                                    ("0" + message_datetime.getMinutes()).slice(-2)}
                            </p>
                        </div>
                    </div>
                </div>
        }
        <div className="popper-root">
            <div
                className="popper-box rounded-md border border-slate-150 bg-white py-1.5 font-inter dark:border-navy-500 dark:bg-navy-700"
            >
                <ul>
                    <li>
                        <a
                            id={"openFeedbackModelLink_" + id}
                            href="#"
                            className="flex h-8 items-center space-x-3 px-3 pr-8 font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 dark:hover:bg-navy-600 dark:hover:text-navy-100 dark:focus:bg-navy-600 dark:focus:text-navy-100"
                            onClick={(event) => openFeedbackModal(event, id)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="mt-px h-5 w-5"
                                fill="none"
                                viewBox="0 0 48 48"
                                stroke="currentColor"
                                strokeWidth="1.5"
                            >
                                <path fill="#78909C"
                                      d="M40,41H8c-2.2,0-4-1.8-4-4l0-20.9c0-1.3,0.6-2.5,1.7-3.3L24,0l18.3,12.8c1.1,0.7,1.7,2,1.7,3.3V37 C44,39.2,42.2,41,40,41z"/>
                                <rect x="12" y="11" fill="#fff" width="24" height="22"/>
                                <polygon fill="#9C27B0" points="24,13.6 18,21.4 30,21.4"/>
                                <path fill="#CFD8DC"
                                      d="M40,41H8c-2.2,0-4-1.8-4-4l0-20l20,13l20-13v20C44,39.2,42.2,41,40,41z"/>
                            </svg>
                            <span>Feedback</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div
            id={"feedbackModal_" + id}
            className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white dark:bg-navy-700"
            >
                <div
                    className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5"
                >
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Submit The Feedback
                    </h3>
                    <button
                        data-close-modal
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <form className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
                    <div className="mt-4 space-y-4">
                        <label className="block">
                            <ReactQuill theme="snow" className="h-40 w-full inline-block"
                                        value={feedbackInputs.content}
                                        onChange={handleFeedbackHTMLChange}
                            />
                        </label>
                        <div className="space-x-2 text-right">
                            <button
                                data-close-modal
                                className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                onClick={(event) => event.preventDefault()}
                            >
                                Cancel
                            </button>
                            <button
                                data-close-modal
                                onClick={submitFeedback}
                                className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
}

export default ChatMessage;