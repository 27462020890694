import { createContext, useContext, useState } from 'react';

const CourseContext = createContext();

export const useCourseContext = () => useContext(CourseContext);

export const CourseProvider = ({ children }) => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courses, setCourses] = useState([]);
  const [user, setUser] = useState(null);

  return (
    <CourseContext.Provider value={{ selectedCourse, setSelectedCourse, courses, setCourses, user, setUser }}>
      {children}
    </CourseContext.Provider>
  );
};

