import { Navigate } from "react-router-dom";

import Layout from "../layout/layout";
import {useAuth} from "./authProvider";
import Cookies from "js-cookie";
import {get_cookies_token} from "./cookieHandler";
import {useCookies} from "react-cookie";

export const ProtectedRoute = () => {

    const {stores} = useAuth();
    const [cookies] = useCookies();

    // Check if the user is authenticated
    // Changed to handle cookiesss
    if (!cookies.token) {
        // If not authenticated, redirect to the login page
        return <Navigate to="/login" />;
    }

    // If authenticated, render the child routes
    return <Layout user={stores.user} course={stores.course}/>;
};
