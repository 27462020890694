import { Grid, styled, CardContent, CardMedia, Paper } from "@mui/material";
import { benefitList } from "./benefitList";

const BenefitContainer = () => {
    const StyledPaper = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        borderRadius: '12px',
        backgroundColor: '#f0f4f8',
        transition: '0.3s ease-in-out',
        height: '100%',
        display: 'flex', 
        flexDirection: 'column', 
        '&:hover': {
            boxShadow: theme.shadows[5],
        },
    }));

    return (
        <Grid id="benefits" className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-slate-300 text-gray-800 mb-8">
                Why Use Generative AI in Teaching?
            </h2>
            {benefitList.map((category, index) => (
                <Grid item xs={12} key={index}>
                    <h3 className="text-xl font-bold md:text-3xl md:leading-tight dark:text-slate-300 my-4 text-gray-600 ml-2">
                        {category.title}
                    </h3>

                    <Grid container spacing={6}>
                        {category.items.map((item, idx) => (
                            <Grid item xs={12} sm={6} md={4} lg={4} key={idx}>
                                <StyledPaper
                                    sx={{ backgroundColor: item.paperColor }}
                                    className="!mb-5 dark:bg-navy-600"
                                >
                                    <Grid
                                        className="w-full h-48 flex justify-center rounded-lg"
                                        sx={{ backgroundColor: item.insideCardColors[1] }}
                                    >
                                        <Grid className="relative flex flex-col items-center w-64 rounded-md">
                                            <Grid className="absolute top-0 left-0 right-0 flex justify-center px-4">
                                                <Grid sx={{ backgroundColor: item.insideCardColors[0] }} className="w-full h-10 rounded-b-xl"></Grid>
                                            </Grid>

                                            <Grid className="flex flex-grow items-center justify-center">
                                                <CardMedia
                                                    component="img"
                                                    alt={item.text}
                                                    sx={{
                                                        width: "64px",
                                                        height: "64px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "flex-start"
                                                    }}
                                                    image={item.image}
                                                />
                                            </Grid>

                                            <Grid className="absolute bottom-0 left-0 right-0 flex justify-center px-4">
                                                <Grid sx={{ backgroundColor: item.insideCardColors[0] }} className=" w-full h-10 rounded-t-xl"></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <CardContent
                                        className="flex text-left flex-col"
                                        sx={{
                                            backgroundColor: item.color,
                                            "&.MuiCardContent-root:last-child": {
                                                paddingBottom: 0
                                            },
                                        }}
                                    >
                                        <h3 className="text-xl font-semibold text-gray-800 dark:text-neutral-300 dark:group-hover:text-white">
                                            {item.text}
                                        </h3>
                                        <p className="mt-2 text-gray-600 dark:text-neutral-400 mp-4">
                                            {item.description}
                                        </p>
                                    </CardContent>
                                </StyledPaper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}

export default BenefitContainer;