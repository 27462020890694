import OneImage from "../../../../../images/one.svg";
import TwoImage from "../../../../../images/two.svg";
import ThreeImage from "../../../../../images/three.svg";
export const getStartedList = [
    {
        title:"Create Your First Course for Free",
        image:OneImage,
        description:"As a teacher, you can create your first course on JOURNiCRAFT free of charge. Use our platform to redesign your course, improve your teaching materials, and provide a more engaging learning experience for your students.Subscribe to Our Newsletter",},
    {
        title:"Subscribe to Our Newsletter" ,
        image: TwoImage,
        description:"If you're interested in learning more about using generative AI in education and playing a key role as a teacher in this era, subscribe to our newsletter. We'll send you valuable content and videos on these topics to help you stay informed and inspired.Share Your Feedback"
    },
    {
        title:"Share Your Feedback",
        description:"JOURNiCRAFT is in its early stages, so we really need your feedback and comments to develop more practical features and provide better service. Your input will help us shape the future of generative AI in higher education.",
        image:ThreeImage
     }

  ]