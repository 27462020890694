import {Link} from "react-router-dom";
import React, {useEffect} from "react";
import {logoIcon, logotypeWithIcon} from "../../../utils/svgs";

const PrivacyPage = () => {

    return <div>
        <header
            className="sticky top-0 inset-x-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full text-sm py-4 bg-navy-900 sm:py-2">
            <nav
                className="relative max-w-[85rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8"
                aria-label="Global">
                <div className="flex items-center justify-between">
                    <Link to="/">
                        {logotypeWithIcon("h-16", "0 0 1400 300")}
                    </Link>
                    <div className="sm:hidden">
                        <button type="button"
                                className="hs-collapse-toggle w-9 h-9 flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-white/20 text-white hover:border-white/40 disabled:opacity-50 disabled:pointer-events-none"
                                data-hs-collapse="#navbar-collapse-with-animation"
                                aria-controls="navbar-collapse-with-animation" aria-label="Toggle navigation">
                            <svg className="hs-collapse-open:hidden flex-shrink-0 w-4 h-4"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round">
                                <line x1="3" x2="21" y1="6" y2="6"/>
                                <line x1="3" x2="21" y1="12" y2="12"/>
                                <line x1="3" x2="21" y1="18" y2="18"/>
                            </svg>
                            <svg className="hs-collapse-open:block hidden flex-shrink-0 w-4 h-4"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round">
                                <path d="M18 6 6 18"/>
                                <path d="m6 6 12 12"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div id="navbar-collapse-with-animation"
                     className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow sm:block">
                    <div
                        className="flex flex-col gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:ps-7">
                        <a className="font-medium text-white/[.8] hover:text-white sm:py-6"
                           href="/home#about">About</a>
                        <a className="font-medium text-white/[.8] hover:text-white sm:py-6"
                           href="/home#features">Features</a>
                        <a className="font-medium text-white/[.8] hover:text-white sm:py-6" href="/home#pricing">
                            Pricing</a>
                        <a className="font-medium text-white/[.8] hover:text-white sm:py-6" href="/home#FAQ">
                            FAQ</a>
                        <a className="font-medium text-white/[.8] hover:text-white sm:py-6" href="#footer">
                            Contact</a>
                        <a className="flex items-center gap-x-2 font-medium text-jclogo-50 hover:text-jclogo-300 sm:border-s sm:border-white/[.3] sm:my-6 sm:ps-6"
                           href="/login">
                            <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3"
                                 strokeLinecap="round" strokeLinejoin="round">
                                <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"/>
                                <circle cx="12" cy="7" r="4"/>
                            </svg>
                            Register / Login
                        </a>
                    </div>
                </div>
            </nav>
        </header>

        <div id="privacy" className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="grid md:grid-cols-2 gap-12">
                <div className="lg:w-3/4">
                    <h2 className="text-3xl text-gray-800 font-bold lg:text-4xl dark:text-white">
                        JOURNiCRAFT Privacy Policies
                    </h2>
                    <p className="mt-3 text-gray-800 dark:text-gray-400">
                        These are policies of the JOURNiCRAFT online platform and potential research conducted regarding the usage of AI-tools:
                    </p>
                    <p className="mt-5">
                        <a className="inline-flex items-center gap-x-1 font-bold text-jclogo-300"
                           href="#">
                            Contact us to learn more
                            <svg className="flex-shrink-0 w-4 h-4 transition ease-in-out group-hover:translate-x-1"
                                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                 strokeLinejoin="round">
                                <path d="m9 18 6-6-6-6"/>
                            </svg>
                        </a>
                    </p>
                </div>

                <div className="space-y-6 lg:space-y-10">
                    <div className="flex">
                        <span
                            className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
                          <svg className="flex-shrink-0 w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                               viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                               strokeLinecap="round"
                               strokeLinejoin="round"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"/><path
                              d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"/>
                          </svg>
                        </span>
                        <div className="ms-5 sm:ms-8">
                            <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                Privacy and Feedback
                            </h3>
                            <p className="mt-1 text-gray-600 dark:text-gray-400">
                                Teacher will have access to view her/his course's chats and provide feedback based on students interactions.
                            </p>
                        </div>
                    </div>

                    <div className="flex">
                        <span
                            className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
                          <svg className="flex-shrink-0 w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                               viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                               strokeLinecap="round"
                               strokeLinejoin="round"><path
                              d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z"/><path
                              d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1"/>
                          </svg>
                        </span>
                        <div className="ms-5 sm:ms-8">
                            <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                Data Recording
                            </h3>
                            <p className="mt-1 text-gray-600 dark:text-gray-400">
                                Survey responses and chat interactions will be recorded for research purposes only.
                            </p>
                        </div>
                    </div>

                    <div className="flex">
                        <span
                            className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
                          <svg className="flex-shrink-0 w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                               viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                               strokeLinecap="round"
                               strokeLinejoin="round"><path d="M7 10v12"/><path
                              d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z"/>
                          </svg>
                        </span>
                        <div className="ms-5 sm:ms-8">
                            <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                Data Retention
                            </h3>
                            <p className="mt-1 text-gray-600 dark:text-gray-400">
                                All identifiable data will be removed from the recordings within three months after the course end date. Any remaining data will be anonymized and used solely for research purposes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer id="footer" className="bg-gray-900 w-full">
            <div className="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 lg:pt-20 mx-auto">
                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6">
                    <div className="col-span-full lg:col-span-1">
                        <a className="flex-none text-xl font-semibold text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                           href="#" aria-label="Brand"> {logoIcon("h-40", "0 0 550 550")}</a>
                    </div>

                    <div className="col-span-1">
                        <h4 className="font-semibold text-gray-100">Product</h4>

                        <div className="mt-3 grid space-y-3">
                            <p><a
                                className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                href="#pricing">Pricing</a></p>
                            <p><a
                                className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                href="#">Changelog <span
                                className="inline ms-1 text-xs bg-blue-700 text-white py-1 px-2 rounded-lg">2.1.0</span></a>
                            </p>
                            <p><a
                                className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                href="#">Docs</a></p>
                        </div>
                    </div>

                    <div className="col-span-1">
                        <h4 className="font-semibold text-gray-100">Company</h4>

                        <div className="mt-3 grid space-y-3">
                            <p><a
                                className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                href="#about">About us</a></p>
                            <p><a
                                className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                href="#">Blog</a></p>
                            <p><a
                                className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                href="#">Customers</a></p>
                        </div>
                    </div>

                    <div className="col-span-2">
                        <h4 className="font-semibold text-gray-100">Stay up to date</h4>

                        <form>
                            <div
                                className="mt-4 flex flex-col items-center gap-2 sm:flex-row sm:gap-3 bg-white rounded-lg p-2 dark:bg-gray-800">
                                <div className="w-full">
                                    <label htmlFor="hero-input" className="sr-only">Search</label>
                                    <input type="text" id="hero-input" name="hero-input"
                                           className="py-3 px-4 block w-full border-transparent rounded-lg text-sm focus:border-jclogo-300 focus:ring-jclogo-300 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-transparent dark:text-gray-400 dark:focus:ring-gray-600"
                                           placeholder="Enter your email"/>
                                </div>
                                <a className="w-full sm:w-auto whitespace-nowrap p-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-jclogo-200 text-white hover:bg-jclogo-400 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                   href="#">
                                    Subscribe
                                </a>
                            </div>
                            <p className="mt-3 text-sm text-gray-400">
                                Contact us via
                                <a
                                    className="text-jclogo-400 transition-colors hover:text-jclogo-600 dark:text-jclogo-100 dark:hover:text-jclogo-400"
                                    href="mailto:journicraft.com@gmail.com">
                                    &nbsp;&nbsp;journicraft.com@gmail.com
                                </a>
                            </p>
                        </form>
                    </div>
                </div>

                <div className="mt-5 sm:mt-12 grid gap-y-2 sm:gap-y-0 sm:flex sm:justify-between sm:items-center">
                    <div className="flex justify-between items-center">
                        <p className="text-sm text-gray-400">© 2024 JOURNiCRAFT. All rights reserved.</p>
                    </div>

                    <div>
                        <a className="w-10 h-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                           href="#">
                            <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16"
                                 height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path
                                    d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                            </svg>
                        </a>
                        <a className="w-10 h-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                           href="#">
                            <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16"
                                 height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path
                                    d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
                            </svg>
                        </a>
                        <a className="w-10 h-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                           href="#">
                            <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16"
                                 height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path
                                    d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
}

export default PrivacyPage;