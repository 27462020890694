export const faqsList = [
    {
        question: "Getting Started as a Student",
        first: "As a student, please follow these steps:",
        answer: `Click the + button in the header.
        Select "Join a Course"
        Enter your course code.`,
        last: "Once submitted, your teacher or teaching assistant will approve your access to the chat and exercises.",
        type: "list"
    },
    {
        question: "Getting Started as a Teacher",
        first: "As a teacher, you need to create a course first. Follow these steps:",
        answer: `Click on the + button in the header
        Select "Request to Create a Course"
        Enter your phone number and submit
        Once submitted, the support staff will quickly check and approve your request. In the worst case, it may take up to one day.
        After your request is approved, your role will change to 'teacher', and you can create your course using the same menu.`,
        last: "",
        type: "list"
    },
    {
        question: "Contact Us",
        answer: "You can easily contact us at journicraft.com@gmail.com"
    }
];