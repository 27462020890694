import React, {useEffect, useState} from "react";
import FaqContainer from "./itemss/faq//faqContainer";
import Introduction from "./itemss/introduction/IntroductionContainer";
import JoinMessage from "./itemss/joinMessage/joinMessageContainer";
import PricingContainer from "./itemss/pricing/pricingContainer";
import GetStartedContainer from "./itemss/getstarted/getStartedContainer";
import HelpContainer from "./itemss/help/helpContainer";
import Footer from "./itemss/footer/footer";
import BenefitContainer from "./itemss/benefit/benefitContainer";
import WebsiteHeader from "./itemss/header/header";

const Firstpage = () => {
    const getColorFromScroll = (scrollY) => {
        // Array of pastel colors
        const colors = [
            {start: '#bae1ff', end: '#dbd7ff'},
            {start: '#dbd7ff', end: '#f7d6e6'},
            {start: '#f7d6e6', end: '#bae1ff'},
        ];

        // Determine which color set to use based on scroll position
        const index = Math.floor(scrollY / window.innerHeight) % colors.length;
        const {start, end} = colors[index];

        return `linear-gradient(to bottom, ${start}, ${end}, #ffffff)`;
    };
    const [background, setBackground] = useState(getColorFromScroll(0));

    useEffect(() => {
        const handleScroll = () => {
            setBackground(getColorFromScroll(window.scrollY));
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return <div>
        <WebsiteHeader/>
        <div className="flex flex-col md:flex-row flex-wrap mt-8 sm:mt-14 justify-between md:mx-24 p-4">
            <Introduction/>
        </div>
        <div className="flex flex-col p-4 justify-between md:mx-24">
            <BenefitContainer/>
        </div>
        {/* <div className=" p-4" style={{ background }}> */}
        <div className=" p-4 justify-between md:mx-24">
            <HelpContainer/>
        </div>
        <div className="flex flex-col md:flex-row justify-between md:mx-24  p-4">
            <GetStartedContainer/>
        </div>
        <div className="flex flex-col md:flex-row justify-between md:mx-24  p-4">
            <PricingContainer/>
        </div>
        <div className="flex flex-col md:flex-row justify-between md:mx-24  mt-8 sm:mt-14 p-4">
            <JoinMessage/>
        </div>
        <div className="flex flex-col mt-8 justify-between md:mx-24 sm:mt-14 p-4">
            <FaqContainer/>
        </div>
        <Footer/>
    </div>
}

export default Firstpage;