import React, {useEffect, useState} from "react";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";

import Modal from '../../../components/modal'

import FetchClient from "../../../utils/fetchClient";
import {useLayoutContext} from "../../../layout/layoutProvider";
import ListMenu from "../../../components/listmenu";
import ReactQuill from "react-quill";
import { openCloseSidebarPanel } from "../../../utils/helpers";

const ExerciseListSidebarMenu = (props) => {
    const navigate = useNavigate();

    const {setLoader, sidebarSelectedMenuItem} = useLayoutContext();
    const [exercises, setExercises] = useState([]);
    const [inputs, setInputs] =
        useState({title: "", content: "", type: "case", description: ""});
    const [selectedExercise, setSelectedExercise] = useState()

    const user = props.user;
    const course = props.course;
    openCloseSidebarPanel(true);
    const getExercises = () => (
        FetchClient({
            method: 'get',
            url: `course/` + course.id + '/exercises',
            params: {
                course_id: course.id
            }
        }).then(response => {
            setExercises(response.data);
            setLoader(false);
        })
    );
    useEffect(() => {
        setLoader(true);
        getExercises();
    }, []);

    function selectThisExercise(event, exercise) {
        event.preventDefault();
        setSelectedExercise(exercise);
        setInputs({title:exercise.title, content:exercise.content, description:exercise.description,
            type:exercise.type
        });
    }

    const handleDeleteExercise = (event) => {
        event.preventDefault();
        setLoader(true);
        FetchClient.delete('exercises/' + selectedExercise.id, {
            data: {
                exercise_id: selectedExercise.id,
                course_id : props?.course?.id
            }
        }).then(response => {
            exercises.splice(exercises.findIndex(function (i) {
                return i.id === selectedExercise.id;
            }), 1);
            setExercises(exercises);
            setInputs({title: "", content: "", type: "case", description: ""});
            window.$notification({text: 'Exercise deleted successfully!', variant: 'error', position: 'center-top'})
            setLoader(false);
            navigate("/" + sidebarSelectedMenuItem, {replace: true});
        });
    }
    const handleContentHTMLChange = (content, delta, source, editor) => {
        let newInputs = inputs;
        newInputs.content = content;
        setInputs(newInputs);
    }
    const handleDescriptionHTMLChange = (description, delta, source, editor) => {
        let newInputs = inputs;
        newInputs.description = description;
        setInputs(newInputs);
    }
    const handleChange = (event) => {
        const {name, value} = event.target;
        setInputs(values => ({...values, [name]: value}))
    }
    const keyUpHandler = (event, mode) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (mode === "create")
                submitExercise(event);
            else if (mode === "update")
                updateExercise(event);
            return false;
        }
        return true;
    };
    const submitExercise = (event) => {
        event.preventDefault();
        FetchClient({
            method: 'post',
            url: `exercises`,
            data: {
                title: inputs.title,
                content: inputs.content,
                description: inputs.description,
                type: 'case',
                course_id: course.id
            }
        }).then(response => {
            setInputs({title: "", content: "", type: "case", description: ""});
            setExercises((prev)=>[...prev,response.data]);
            navigate("/" + sidebarSelectedMenuItem + "/course/" + course.id + "/exercise/" + response.data.id, {replace: true});
        });
    }
    const updateExercise = (event) => {
        event.preventDefault();
        setLoader(true);
        selectedExercise.title = inputs.title;
        selectedExercise.description = inputs.description;
        selectedExercise.content = inputs.content;
        selectedExercise.type = inputs.type;
        selectedExercise.course_id = course.id;
        FetchClient.put('exercises/' + selectedExercise.id, selectedExercise)
            .then(response => {
                let response_exercise = exercises.findIndex((obj => obj.id === selectedExercise.id));
                exercises[response_exercise].title = inputs.title;
                exercises[response_exercise].content = inputs.content;
                exercises[response_exercise].description = inputs.description;
                exercises[response_exercise].type = inputs.type;
                setExercises(exercises);

                setLoader(true);
                navigate("/" + sidebarSelectedMenuItem + "/course/" + course.id + "/exercise/" + selectedExercise.id, {replace: true});
            });
    }
    useEffect(() => {
        new Modal("#createExerciseModal");
    }, []);
    useEffect(() => {
        new Modal("#deleteExerciseModal");
        new Modal("#updateExerciseModal");
        const listMenuWrapper = document.querySelector(`#${"exerciseMenuWrapper"}`);
        if (listMenuWrapper) {
            new ListMenu(listMenuWrapper);
        }
    }, [exercises]);

    const exerciseItems = exercises.map((e) => (<li className="flex flex-row" key={e.id}>
        <Link
            to={"/exercise_list/course/" + course.id + "/exercise/" + e.id}
            data-menu-id={"exercisemenu_" + e.id}
            data-default-class="text-slate-600 hover:text-slate-800 dark:text-navy-200 dark:hover:text-navy-50"
            data-active-class="font-medium text-primary dark:text-accent-light"
            className="listmenu-item flex-1 nav-link flex py-2 text-xs+ tracking-wide outline-none transition-colors duration-300 ease-in-out"
        >
            {e.title}
        </Link>
        <button
            className="flex darkmode-toggle btn h-6 w-6 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
            data-toggle="modal"
            data-target="#updateExerciseModal"
            id={"edit" + e.id}
            onClick={(event) => selectThisExercise(event, e)}
        >
            <img
                className="mx-auto w-4 h-4"
                src={process.env.PUBLIC_URL + '/images/buttons/edit.png'}
                alt="edit"
            />
        </button>
        <button
            className="flex darkmode-toggle btn h-6 w-6 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
            data-toggle="modal"
            data-target="#deleteExerciseModal"
            id={"delete" + e.id}
            onClick={(event) => selectThisExercise(event, e)}
        >
            <img
                className="mx-auto w-4 h-4"
                src={process.env.PUBLIC_URL + '/images/buttons/delete.png'}
                alt="edit"
            />
        </button>
    </li>));

    return <div className="min-h-100vh flex grow bg-slate-50 dark:bg-navy-900">
        <div className="sidebar-panel">
            <div id="page-sidebar-panel"
                className="flex  h-full grow flex-col bg-white pl-[var(--main-sidebar-width)] dark:bg-navy-750"
            >
                <div
                    className="flex h-18 w-full items-center justify-between pl-4 pr-1"
                >
                    <div className="flex items-center">
                        <div className="avatar mr-3 hidden h-9 w-9 lg:flex">
                            <div
                                className="is-initial rounded-full bg-primary/10 text-primary dark:bg-accent-light/10 dark:text-accent-light"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path fill="#1565C0"
                                          d="M25,22h13l6,6V11c0-2.2-1.8-4-4-4H25c-2.2,0-4,1.8-4,4v7C21,20.2,22.8,22,25,22z"/>
                                    <path fill="#2196F3"
                                          d="M23,19H10l-6,6V8c0-2.2,1.8-4,4-4h15c2.2,0,4,1.8,4,4v7C27,17.2,25.2,19,23,19z"/>
                                    <g fill="#2196F3">
                                        <circle cx="12" cy="31" r="5"/>
                                        <circle cx="36" cy="31" r="5"/>
                                    </g>
                                    <g fill="#607D8B">
                                        <path d="M20,42c0,0-2.2-4-8-4s-8,4-8,4v2h16V42z"/>
                                        <path d="M44,42c0,0-2.2-4-8-4s-8,4-8,4v2h16V42z"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <p
                            className="text-base tracking-wider text-slate-800 dark:text-navy-100"
                        >
                            List of Exercises
                        </p>
                    </div>
                    <button
                        className="sidebar-close btn h-7 w-7 rounded-full p-0 text-primary hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-accent-light/80 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25 xl:hidden"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 19l-7-7 7-7"
                            />
                        </svg>
                    </button>
                </div>
                <div
                    className="nav-wrapper overflow-y-hidden overflow-x-hidden pb-6 text-center"
                    data-simplebar
                >
                    <button
                        className="btn mt-5 w-[calc(80%-1rem)] bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                        data-toggle="modal"
                        data-target="#createExerciseModal"
                        onClick={(event) => setInputs({title: "", content: "", type: "case", description: ""})}
                    >
                        Create an Exercise
                    </button>

                    <div className="my-3 mx-4 h-px bg-slate-200 dark:bg-navy-500"></div>
                </div>
                <div id="exerciseMenuWrapper"
                     className="nav-wrapper h-[calc(100%-4.5rem)] overflow-x-hidden pb-6"
                     data-simplebar
                >
                    <div className="listmenu">
                        <ul className="flex flex-1 flex-col px-4 font-inter">
                            {exerciseItems}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div
            className="modal fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            id="deleteExerciseModal"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content scrollbar-sm relative flex max-w-lg flex-col items-center overflow-y-auto rounded-lg bg-white px-4 py-10 text-center dark:bg-navy-700 sm:px-5"
            >
                <img
                    className="mx-auto h-16 w-16"
                    src={process.env.PUBLIC_URL + '/images/buttons/delete.png'}
                    alt="logo"
                />

                <div className="mt-4">
                    <h2 className="text-2xl text-slate-700 dark:text-navy-100">
                        Delete
                    </h2>
                    <p className="mt-2">
                        Are you sure you want to delete exercise?
                    </p>
                    <div className="space-x-2 text-right mt-4">
                        <button
                            data-close-modal
                            onClick={handleDeleteExercise}
                            className="btn min-w-[7rem] rounded-full bg-error font-medium text-white hover:bg-error-focus focus:bg-error-focus active:bg-error-focus/90 dark:bg-orange dark:hover:bg-orange-focus dark:focus:bg-orange-focus dark:active:bg-orange/90"
                        >
                            Delete
                        </button>
                        <button
                            data-close-modal
                            className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                            onClick={(event) => event.preventDefault()}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="createExerciseModal"
            className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white dark:bg-navy-700"
            >
                <div
                    className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5"
                >
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Create an Exercise
                    </h3>
                    <button
                        data-close-modal
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <form className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5"
                      onKeyUp={(event) => keyUpHandler(event, "create")}>
                    <p>
                        Specify your exercise's title and type.
                    </p>
                    <div className="mt-4 space-y-4">
                        <label className="block">
                            <span>Type:</span>
                            <select
                                className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                            >
                                <option>Case</option>
                            </select>
                        </label>
                        <label className="block">
                            <span>Title:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="Your Exercise Title"
                                name="title"
                                value={inputs.title}
                                onChange={handleChange}
                                type={"text"}
                            />
                        </label>
                        <label className="block">
                            <span>Description (for students):</span>
                            <ReactQuill theme="snow" className="h-40 w-full inline-block"
                                        value={inputs.description}
                                        onChange={handleDescriptionHTMLChange}
                            />
                        </label>
                        <label className="block">
                            <span>Content (for GPT):</span>
                            <ReactQuill theme="snow" className="h-40 w-full inline-block"
                                        value={inputs.content}
                                        onChange={handleContentHTMLChange}
                            />
                        </label>
                        <div className="space-x-2 text-right">
                            <button
                                data-close-modal
                                className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                onClick={(event) => event.preventDefault()}
                            >
                                Cancel
                            </button>
                            <button
                                data-close-modal
                                onClick={submitExercise}
                                className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                            >
                                Create
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div
            id="updateExerciseModal"
            className="modal modal-scale fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
            role="dialog"
        >
            <div className="modal-overlay absolute inset-0 bg-slate-900/60"></div>
            <div
                className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden rounded-lg bg-white dark:bg-navy-700"
            >
                <div
                    className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5"
                >
                    <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                        Edit the exercise
                    </h3>
                    <button
                        onClick={() => setInputs({title: "", content: "", type: "case", description: ""})                    }
                        data-close-modal
                        className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
                <form className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5"
                      onKeyUp={(event) => keyUpHandler(event, "update")}>
                    <p>
                        Specify your exercise's title and type.
                    </p>
                    <div className="mt-4 space-y-4">
                        <label className="block">
                            <span>Type:</span>
                            <select
                                className="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                            >
                                <option>Case</option>
                            </select>
                        </label>
                        <label className="block">
                            <span>Title:</span>
                            <input
                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                placeholder="Your Exercise Title"
                                name="title"
                                value={inputs.title}
                                onChange={handleChange}
                                type={"text"}
                            />
                        </label>
                        <label className="block">
                            <span>Description (for students):</span>
                            <ReactQuill theme="snow" className="h-40 w-full inline-block"
                                        value={inputs.description}
                                        onChange={handleDescriptionHTMLChange}
                            />
                        </label>
                        <label className="block">
                            <span>Content (for GPT):</span>
                            <ReactQuill theme="snow" className="h-40 w-full inline-block"
                                        value={inputs.content}
                                        onChange={handleContentHTMLChange}
                            />
                        </label>
                        <div className="space-x-2 text-right">
                            <button
                                data-close-modal
                                className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                                onClick={(event) => event.preventDefault()}
                            >
                                Cancel
                            </button>
                            <button
                                data-close-modal
                                onClick={updateExercise}
                                className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <Outlet/>
    </div>
}

export default ExerciseListSidebarMenu;