import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import {CookiesProvider} from "react-cookie";

import './css/app.css';
import AuthProvider from "./js/utils/authProvider";
import LayoutContextProvider from "./js/layout/layoutProvider";
import JRoutes from "./js/routes";
import ReactGA from 'react-ga';
import TagManager from "react-gtm-module";
import { CourseProvider } from './js/utils/courseContext';
const tagManagerArgs = {
    gtmId:'G-NQZCZLRE58'
}    
const TRACKING_ID = "G-NQZCZLRE58"; 

ReactGA.initialize(TRACKING_ID);
TagManager.initialize(tagManagerArgs);
const handleOffline = () => {
    window.$notification({
        text: 'You are currently offline. Please check your internet connection.',
        variant: 'error',
        position: 'center-top'
    })
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider>
        <AuthProvider>
             <CourseProvider>
            <LayoutContextProvider>
                <JRoutes/>
            </LayoutContextProvider>
            </CourseProvider>
        </AuthProvider>
    </CookiesProvider>
);

window.addEventListener("DOMContentLoaded", () => {
    window.addEventListener('offline', handleOffline);
});