import TimeSavingImage from "../../../../../images/time.svg";
import CreativityImage from "../../../../../images/creativity.svg";
import AccessibilityImage from "../../../../../images/accessible.svg";
import PersonalizedImage from "../../../../../images/personalized.svg";
import EngagementImage from "../../../../../images/engagement.svg";
import UnderstandingImage from "../../../../../images/understanding.svg"; 

export const benefitList = [
    {
      title: 'Benefits for Teachers',
      items: [
        {
          text: 'Time-saving',
          description: 'By delegating the responsibility of teaching content to generative AI, teachers can focus on providing higher-level insights and critical thinking skills.',
          paperColor: '#FCF2FE', 
          image: TimeSavingImage,
          insideCardColors:[
            "#F6B2FF","#DF9AF8"
          ]
        },
        {
          text: 'Enhanced Creativity',
          description: 'Generative AI can help spark new ideas and encourage students to think outside the box.',
          paperColor: '#F0F0FF',  
          image: CreativityImage,
          insideCardColors:[
            "#B0B0FE","#B0A0F9"
          ]
        },
        {
          text: 'Improved Accessibility',
          description: 'Generative AI can assist diverse learners with different abilities, linguistic backgrounds, or accessibility needs.',
          paperColor: '#FFFAE6', 
          image : AccessibilityImage,
          insideCardColors:[
            "#FFE489","#FEDDA9"
          ]
        },
      ],
    },
    {
      title: 'Benefits for Students',
      items: [
        {
          text: 'Personalized Learning',
          description: 'Generative AI can adapt to each student\'s unique needs and learning style, providing a more engaging and effective learning experience.',
          paperColor: '#F8FBE7', 
          image: PersonalizedImage,
          insideCardColors:[
            "#D3E776","#B4DA6D"
          ]
        },
        {
          text: 'Increased Engagement',
          description: 'Generative AI can make learning more interactive and fun, keeping students motivated and interested.',
          paperColor: '#E4F7F9',  
          image: EngagementImage,
          insideCardColors:[
            "#5CDCE8","#41C7D8"
          ]
        },
        {
          text: 'Better Understanding',
          description: 'Generative AI can provide students with a deeper understanding of complex concepts through interactive simulations and explanations.',
          paperColor: '#BCF5D8',  
          image: UnderstandingImage,
          insideCardColors:[
            "#86EDB8","#6ED9AA"
          ]
        },
      ],
    },
  ];