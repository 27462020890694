import FetchClient from "../../../utils/fetchClient";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {useAuth} from "../../../utils/authProvider";
import {useLayoutContext} from "../../../layout/layoutProvider";
import ReactQuill from "react-quill";
import {Dialog, Tab, Tabs} from "@mui/material";
import * as yup from 'yup';
import moment from "moment";
import Select from 'react-select';
import TabPanel from "../../../components/tab_pannel";
import {CustomStyles} from "../../../components/selectOptions";
import {openCloseSidebarPanel} from "../../../utils/helpers";
import ClipLoader from "react-spinners/ClipLoader";

const MessageFolder = (props) => {
    const location = useLocation();
    const store = useAuth();
    const {setLoader, setSidebarSelectedMenuItem} = useLayoutContext();
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const navigate = useNavigate();
    const [tab, setTab] = useState(0);

    const courseId = store?.stores?.course?.id;
    const userCourseRole = store?.stores?.course?.role;
    const user = store?.stores?.user;

    const folder = location?.state?.folder;

    const getSentMessages = () => {
        FetchClient({
            method: 'get',
            url: 'course/' + userCourseRole + '/course_emails/sent',
            params: {
                course_id: courseId,
            }
        }).then(response => {
            setMessages(response?.data?.items);
            setLoading(false);
        })
    }
    const getReceivedMessages = () => {
        FetchClient({
            method: 'get',
            url: 'course/' + userCourseRole + '/course_emails',
            params: {
                course_id: courseId,
            }
        }).then(response => {
            setMessages(response?.data?.items);
            setLoading(false);
        })
    }
    useEffect(() => {
        updateMenu();
        setLoading(true);
        if (location.pathname.includes('sent')) {
            getSentMessages();
        } else if (location.pathname.includes('inbox')) {
            getReceivedMessages();
        }
        openCloseSidebarPanel(true);
    }, [location]);

    function updateMenu() {
        if (location.pathname.includes('messages')) {
            setSidebarSelectedMenuItem('messages');
        }
    }

    const handleSelectMessage = (msg) => {
        navigate(`/messages/message/${msg?.id}`, {state: {messageId: msg?.id}, replace: true});
    }

    return (
        <main className={`main-content mt-0 flex flex-col h-100vh`}>
            <div
                className="scrollbar-sm grow overflow-y-auto px-[calc(var(--margin-x)-.5rem)] py-2 transition-all duration-[.25s]"
            >
                <div
                    className={"flex mt-24 text-center items-center text-primary justify-center" + (loading ? "" : " hidden")}>
                    <span className="px-2">Please wait ... </span>
                    <ClipLoader
                        color={"blue"}
                        loading={loading}
                        size={20}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
                <div className={"space-y-5 mt-18 card" + (loading ? " hidden" : " ")}>
                    {messages.length > 0 ? messages.map((msg, index) => (
                        <div key={index} onClick={() => handleSelectMessage(msg)}
                             class="flex cursor-pointer flex-col rounded-t-lg border-b p-2.5 font-semibold text-slate-700 hover:bg-slate-100 dark:border-navy-500 dark:text-navy-100 dark:hover:bg-navy-600 sm:flex-row sm:items-center">
                            <div class="flex items-center justify-between">
                                <div class="flex space-x-2 sm:w-72">
                                    <div class="flex">
                                        <label class="flex h-8 w-8 items-center justify-center"
                                               x-tooltip="'Select'">
                                            <input
                                                class="form-checkbox is-outline h-4.5 w-4.5 rounded border-slate-400/70 before:bg-primary checked:border-primary hover:border-primary focus:border-primary dark:border-navy-400 dark:before:bg-accent dark:checked:border-accent dark:hover:border-accent dark:focus:border-accent"
                                                type="checkbox" disabled={true}/>
                                        </label>
                                    </div>
                                    <div class="flex items-center space-x-2">
                                        <h3 class="line-clamp-1">{msg?.sender_id === user?.id ? "You" : msg?.sender_name}</h3>
                                        <span
                                            class="h-2 w-2 shrink-0 rounded-full bg-secondary lg:hidden"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-1 items-center justify-between space-x-2">
                                <div class="flex items-center space-x-2 px-2">
                                            <span
                                                class="line-clamp-1">{msg?.subject ? msg?.subject : "No subject"} - </span>
                                    <div className="line-clamp-1 text-slate-400"
                                         dangerouslySetInnerHTML={{__html: msg?.body}}></div>
                                </div>
                                <div class="flex sm:hidden">
                                    <button x-tooltip="'Starred'"
                                            class="btn h-8 w-8 rounded-full p-0 text-slate-400 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-300 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                                        <svg xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5"
                                             class="h-5 w-5"
                                             fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"/>
                                        </svg>
                                    </button>
                                    <button x-tooltip="'Actions'"
                                            class="btn h-8 w-8 rounded-full p-0 text-slate-400 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:text-navy-300 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                                        <svg xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5"
                                             class="h-5 w-5"
                                             fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div
                                class="hidden px-2 text-xs+ sm:flex">{moment(msg?.creation_date).format("MMMM DD YYYY")}</div>
                        </div>
                    )) : <div
                        class="w-fit m-12 alert flex justify-center rounded-full bg-warning/10 py-4 px-4 text-warning dark:bg-warning/15 sm:px-5">
                        There is no message!
                    </div>}
                </div>
            </div>
        </main>
    )
}

export default MessageFolder;