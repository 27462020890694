import {Link} from 'react-router-dom'
import {useLayoutContext} from "../layoutProvider";
import {sidebarMenuColors} from "../../utils/constants";

let currentColor = "";

const ChatItem = () => {
    const {sidebarSelectedMenuItem, setSidebarSelectedMenuItem} = useLayoutContext();
    if (sidebarSelectedMenuItem === 'chats') {
        currentColor = sidebarMenuColors.selected;
    } else {
        currentColor = sidebarMenuColors.unselected;
    }

    return (
        <div className="is-scrollbar-hidden chat flex flex-col space-y-4 overflow-y-auto pt-6">
            <Link to="/chats" onClick={(event) => setSidebarSelectedMenuItem('general_chats')}
                  data-tooltip="Chats"
                  data-placement="right"
                  className="tooltip-main-sidebar flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg version="1" xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" viewBox="0 0 512 512"
                     fill="currentColor">
                    {/*<path fill={currentColor} fillOpacity="1"*/}
                    {/*      d="M15,40h23l6,6V25c0-2.2-1.8-4-4-4H15c-2.2,0-4,1.8-4,4v11C11,38.2,12.8,40,15,40z"/>*/}
                    {/*<path fill={currentColor} fillOpacity="0.3"*/}
                    {/*      d="M33,25H10l-6,6V8c0-2.2,1.8-4,4-4h25c2.2,0,4,1.8,4,4v13C37,23.2,35.2,25,33,25z"/>*/}
                    <path fill={currentColor} fillOpacity="0.6"  d="M466,173.8H200.8c-20,0-34.8,16-34.8,36v178.4c0,20,14.8,38,34.8,38h164.4c0.4,0,0.8-0.8,1.2-0.4
	l63.6,62v-62c0-1.2,1.6,0,2.4,0H466c20,0,36-18,36-38v-178C502,189.8,486,173.8,466,173.8z"/>
                    <g>
                        <path fill={currentColor} fillOpacity="1"
                              d="M226,237.8h218.4c1.2,0,2-0.8,2-2s-0.8-2-2-2H226c-1.2,0-2,0.8-2,2S225.2,237.8,226,237.8z"/>
                        <path fill={currentColor} fillOpacity="1"
                              d="M444.8,297.8h-95.6c-1.2,0-2,0.8-2,2s0.8,2,2,2h95.6c1.2,0,2-0.8,2-2S445.6,297.8,444.8,297.8z"/>
                        <path fill={currentColor} fillOpacity="1"
                              d="M226,301.8h79.6c1.2,0,2-0.8,2-2s-0.8-2-2-2H226c-1.2,0-2,0.8-2,2S225.2,301.8,226,301.8z"/>
                        <path fill={currentColor} fillOpacity="1"
                              d="M226,365.8h218.4c1.2,0,2-0.8,2-2s-0.8-2-2-2H226c-1.2,0-2,0.8-2,2S225.2,365.8,226,365.8z"/>
                    </g>
                    <path fill={currentColor} fillOpacity="0.3"  d="M200.8,169.8H342V55c0-20-14.8-37.2-34.8-37.2H42C22,17.8,6,35.4,6,55v178.4c0,20,16,36.4,36,36.4
	h33.2c1.2,0,2.8,0.4,2.8,1.2v66.8l72.4-68H162v-60C162,187.8,178.8,169.8,200.8,169.8z"/>
                    <g>
                        <path fill={currentColor} fillOpacity="1"
                              d="M159.2,141.8H63.6c-1.2,0-2,0.8-2,2s0.8,2,2,2h95.6c1.2,0,2-0.8,2-2S160,141.8,159.2,141.8z"/>
                        <path fill={currentColor} fillOpacity="1"
                              d="M282,141.8h-79.6c-1.2,0-2,0.8-2,2s0.8,2,2,2H282c1.2,0,2-0.8,2-2S282.8,141.8,282,141.8z"/>
                        <path fill={currentColor} fillOpacity="1"
                              d="M63.2,77.8h218.4c1.2,0,2,0.8,2,2s-0.8,2-2,2H63.2c-1.2,0-2-0.8-2-2S62.4,77.8,63.2,77.8z"/>
                        <path fill={currentColor} fillOpacity="1"
                              d="M130.8,205.8H63.2c-1.2,0-2,0.8-2,2s0.8,2,2,2h67.6c1.2,0,2-0.8,2-2S132,205.8,130.8,205.8z"/>
                    </g>
                    <path d="M446,239.8H226c-2.4,0-4-1.6-4-4s1.6-4,4-4h220c2.4,0,4,1.6,4,4S448.4,239.8,446,239.8z"/>
                    <path d="M446,303.8H346c-2.4,0-4-1.6-4-4s1.6-4,4-4h100c2.4,0,4,1.6,4,4S448.4,303.8,446,303.8z"/>
                    <path d="M302,303.8h-76c-2.4,0-4-1.6-4-4s1.6-4,4-4h76c2.4,0,4,1.6,4,4S304.4,303.8,302,303.8z"/>
                    <path d="M446,367.8H226c-2.4,0-4-1.6-4-4s1.6-4,4-4h220c2.4,0,4,1.6,4,4S448.4,367.8,446,367.8z"/>
                    <path d="M432,496.2c-1.2,0-2-0.4-2.8-1.2l-65.6-63.2H200.8c-22.4,0-40.8-19.6-40.8-44v-178c0-23.6,18-42,40.8-42H466
	c23.2,0,42,18.8,42,42v178.4c0,23.6-19.2,44-42,44h-30v60.4c0,1.6-0.8,3.2-2.4,3.6C433.2,496.2,432.4,496.2,432,496.2z M200.8,175.8
	c-18.4,0-32.8,14.8-32.8,34v178.4c0,19.6,14.8,36,32.8,36h164.4c1.2,0,2,0.4,2.8,1.2l60,58v-55.2c0-2.4,1.6-4,4-4h34
	c18,0,34-16.8,34-36V209.8c0-18.8-15.2-34-34-34H200.8z"/>
                    <path d="M282,83.8H62c-2.4,0-4-1.6-4-4s1.6-4,4-4h220c2.4,0,4,1.6,4,4S284.4,83.8,282,83.8z"/>
                    <path d="M162,147.8H62c-2.4,0-4-1.6-4-4s1.6-4,4-4h100c2.4,0,4,1.6,4,4S164.4,147.8,162,147.8z"/>
                    <path d="M282,147.8h-76c-2.4,0-4-1.6-4-4s1.6-4,4-4h76c2.4,0,4,1.6,4,4S284.4,147.8,282,147.8z"/>
                    <path d="M130,211.8H62c-2.4,0-4-1.6-4-4s1.6-4,4-4h68c2.4,0,4,1.6,4,4S132.4,211.8,130,211.8z"/>
                    <path d="M76,346.6c-0.4,0-1.2,0-1.6-0.4c-1.6-0.8-2.4-2-2.4-3.6v-66.8H42c-22.8,0-42-19.6-42-42.4V55c0-23.6,19.2-43.2,42-43.2
	h265.2c22.4,0,40.8,19.6,40.8,43.2v90.8c0,2.4-1.6,4-4,4s-4-1.6-4-4V55c0-19.6-14.8-35.2-32.8-35.2H42c-18.4,0-34,16-34,35.2v178.4
	c0,18.8,15.6,34.4,34,34.4h34c2.4,0,4,1.6,4,4V333l48-48c1.6-1.6,4-1.6,5.6,0s1.6,4,0,5.6l-54.8,55.2C78,346.2,77.2,346.6,76,346.6z
	"/>
                </svg>

            </Link>
        </div>);
}

export default ChatItem;