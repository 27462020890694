import { Link } from "react-router-dom";
import { useLayoutContext } from "../layoutProvider";
import { sidebarMenuColors } from "../../utils/constants";

let currentColor = "";

const CourseItem = (props) => {
  const { sidebarSelectedMenuItem, setSidebarSelectedMenuItem } =
    useLayoutContext();
  if (sidebarSelectedMenuItem === "course") {
    currentColor = sidebarMenuColors.selected;
  } else {
    currentColor = sidebarMenuColors.unselected;
  }

  return (
    <div className="is-scrollbar-hidden course flex flex-col space-y-4 overflow-y-auto pt-6">
      <Link
        to="/course"
        onClick={(event) => setSidebarSelectedMenuItem("course")}
        data-tooltip="Course Page"
        data-placement="right"
        className="tooltip-main-sidebar flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
      >
        <svg
          className="h-7 w-7"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 491.52 491.52"
        >
          <g>
            <path
              fill={currentColor}
              fillOpacity="1"
              d="M46,330.4332v26.219a13.1555,13.1555,0,0,0,13.157,13.156h66.2894l-24.3445,66.8875c-9.1794,27.3587,30.5631,41.822,41.1118,14.9632l7.4982-20.5986H362.29l7.496,20.5986c10.553,26.8631,50.3,12.388,41.1118-14.9632l-24.3445-66.8865-46.5592-.001,12.74,35.0021h-193.47l12.74-35.0021h280.84A13.1551,13.1551,0,0,0,466,356.6522v-26.219Z"
            />

            <path
              fill={currentColor}
              fillOpacity=".5"
              d="M247.2521,247.3477a13.1631,13.1631,0,0,1,13.1635-13.1624H391.5887a13.163,13.163,0,0,1,13.1634,13.1624v56.8355H439.75V59.1917a13.1341,13.1341,0,0,0-13.1335-13.1335H85.3835A13.1341,13.1341,0,0,0,72.25,59.1917V304.1832H247.2521Z"
            />

            <rect
              fill={currentColor}
              fillOpacity=".5"
              height="43.75"
              width="105"
              x="273.5"
              y="260.4332"
            />
          </g>
        </svg>
      </Link>
    </div>
  );
};

export default CourseItem;
