import { useState } from "react";
import {logotypeWithIconMedium} from "../../../../utils/svgs";
import * as yup from 'yup';
import ContactUs from "../contactUs/contactModal";
import FetchClient from "../../../../utils/fetchClient";

const Footer = () => {
    const [email, setEmail ] = useState("");
    const [errorsList, setErrorsList] = useState([]);
    const [openContactModal, setOpenContactModal] = useState(false);
    const handleSubscribe = async () => {
        const schema = yup.object().shape({
            email: yup.string().required("Email is required !").email("Format is incorrect !"),
        });
        try {
            await schema.validate({email}, {abortEarly: false});
            FetchClient({
                method: 'post',
                url: 'contact/news/subscriber',
                data: {
                    email
                }
            }).then(response => {
                setEmail("");
                setErrorsList([]);
                window.$notification({
                    text: 'Successful !',
                    variant: 'success',
                    position: 'center-top'
                })
            }).catch((error) => {
                window.$notification({
                    text: 'Oops! There was an error',
                    variant: 'error',
                    position: 'center-top'
                });
            })
        } catch (err) {
            setErrorsList(err.inner);
        }
    }
    const handleCloseContactModal = () =>{
        setOpenContactModal(false);
       
    }
    const handlePost = (data) => {
        FetchClient({
            method: 'post',
            url: 'contact/us',
            data: {
                email: data.email,
                content: data.message
            }
        }).then(response => {
            setOpenContactModal(false);
            window.$notification({
                text: 'Your message sent successfully !',
                variant: 'success',
                position: 'center-top'
            })            
        }).catch((error)=>{
            window.$notification({
            text: 'Oops! There was an error',
            variant: 'error',
            position: 'center-top'
        });
        })
    }
    return(
        <footer id="footer" className="bg-gray-50 w-full dark:bg-gray-200">
            <div className="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 lg:pt-20 mx-auto">
                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 ">
                    <div className="col-span-full justify-center flex lg:col-span-1">
                        <a className="flex-none text-xl font-semibold text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                        aria-label="Brand"> {logotypeWithIconMedium("h-10 mt-5 show-main-icon ", "0 0 1400 300")}</a>
                    </div>

                    <div className="col-span-1">
                        <h4 className="font-semibold text-black">Product</h4>

                        <div className="mt-3 grid space-y-3">
                            <p><a
                                className="inline-flex gap-x-2 text-black hover:text-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                >Pricing</a></p>
                        </div>
                    </div>

                    <div className="col-span-1">
                        <h4 className="font-semibold text-black">Company</h4>

                        <div className="mt-3 grid space-y-3">
                            <p><a href="#benefits"
                                className="inline-flex gap-x-2 text-black hover:text-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                >benefits</a></p>
                            <p><a
                                className="inline-flex gap-x-2 text-black hover:text-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                href="https://medium.com/@journicraft" target="_blank">Blog</a></p>
                            {/* <p><a
                                className="inline-flex gap-x-2 text-black hover:text-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                >Customers</a></p> */}
                        </div>
                    </div>

                    <div className="col-span-2">
                        <h4 className="font-semibold text-black">Stay up to date</h4>

                        <form>
                            <div
                                className="mt-4 flex flex-col items-center gap-2 sm:flex-row sm:gap-3 bg-white rounded-lg p-2 dark:bg-gray-800">
                                <div className="w-full">
                                    <label htmlFor="hero-input" className="sr-only">Search</label>
                                    <input value={email} onChange={(e)=> setEmail(e.target.value)} type="email" id="email" name="email"
                                        className="py-3 px-4 block w-full border-transparent rounded-lg text-sm focus:border-jclogo-300 focus:ring-jclogo-300 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-transparent dark:text-black dark:focus:ring-gray-600"
                                        placeholder="Enter your email"/>
                                <span className="text-error-focus">{errorsList.filter((name) => name.path === "email")?.[0]?.errors?.[0]}</span>
                                </div>
                                <a className="w-full cursor-pointer sm:w-auto whitespace-nowrap p-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-jclogo-200 text-white hover:bg-jclogo-400 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                onClick={handleSubscribe}>
                                    Subscribe
                                </a>
                            </div>
                            <p onClick={() => setOpenContactModal(true)} className="mt-3  cursor-pointer text-sm text-black">
                                Contact us
                                {/* <a 
                                    className="text-jclogo-400  transition-colors hover:text-jclogo-600 dark:text-jclogo-100 dark:hover:text-jclogo-400"
                                    href="mailto:journicraft.com@gmail.com">
                                    &nbsp;&nbsp;journicraft.com@gmail.com
                                </a> */}
                            </p>
                        </form>
                    </div>
                </div>

                <div className="mt-5 sm:mt-12 grid gap-y-2 sm:gap-y-0 sm:flex sm:justify-between sm:items-center">
                    <div className="flex justify-between items-center">
                        <p className="text-sm text-black">© 2024 JOURNiCRAFT. All rights reserved.</p>
                    </div>

                    <div>
                        <a className="w-10 h-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                        href="mailto:journicraft.com@gmail.com" title="Mail">
                          <svg fill="#ffffff" width="24px" height="24px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M0 1694.235h1920V226H0v1468.235ZM112.941 376.664V338.94H1807.06v37.723L960 1111.233l-847.059-734.57ZM1807.06 526.198v950.513l-351.134-438.89-88.32 70.475 378.353 472.998H174.042l378.353-472.998-88.32-70.475-351.134 438.89V526.198L960 1260.768l847.059-734.57Z" fillRule="evenodd"></path> </g></svg> </a>
                        <a target="_blank" title="Medium" className="w-10 h-10 inline-flex justify-center items-center gap-x-2 gap-y-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                        href="https://medium.com/@journicraft">
                            <svg width="24px"  height="24px" viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg" fill="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="none" fillRule="evenodd"> <path stroke="#ffffff" strokeWidth="12" d="M61.126 56c10.798 0 20.571 4.418 27.646 11.554 7.094 7.155 11.478 17.04 11.478 27.956 0 10.915-4.384 20.8-11.478 27.954-7.075 7.136-16.849 11.554-27.646 11.554-10.798 0-20.572-4.418-27.648-11.554C26.384 116.31 22 106.425 22 95.51c0-10.916 4.384-20.801 11.478-27.956C40.553 60.418 50.328 56 61.126 56Zm72.062 2.666c1.766 0 3.404.768 4.92 1.96 2.18 1.716 4.087 4.273 5.726 7.384 3.706 7.038 5.917 16.775 5.917 27.5 0 10.723-2.21 20.46-5.917 27.498-1.638 3.112-3.545 5.67-5.726 7.385-1.515 1.192-3.153 1.96-4.919 1.96-1.765 0-3.403-.768-4.918-1.96-2.181-1.716-4.088-4.273-5.727-7.384-3.706-7.039-5.917-16.776-5.917-27.5 0-10.722 2.21-20.46 5.917-27.499 1.638-3.111 3.545-5.668 5.726-7.384 1.515-1.192 3.152-1.96 4.918-1.96Z"></path> <path fill="#ffffff" fillRule="nonzero" d="M168.065 57.128c4.381 0 7.935 17.184 7.935 38.382 0 21.191-3.552 38.381-7.935 38.381-4.384 0-7.934-17.185-7.934-38.381s3.552-38.382 7.934-38.382Z"></path> </g> </g></svg>
                        </a>
                        <a className="w-10 h-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                        href="#">
<svg fill="#ffffff" width="24px" height="24px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M1168 601.321v74.955c72.312-44.925 155.796-71.11 282.643-71.11 412.852 0 465.705 308.588 465.705 577.417v733.213L1438.991 1920v-701.261c0-117.718-42.162-140.06-120.12-140.06-74.114 0-120.12 23.423-120.12 140.06V1920l-483.604-4.204V601.32H1168Zm-687.52-.792v1318.918H0V600.53h480.48Zm-120.12 120.12H120.12v1078.678h240.24V720.65Zm687.52.792H835.267v1075.316l243.364 2.162v-580.18c0-226.427 150.51-260.18 240.24-260.18 109.55 0 240.24 45.165 240.24 260.18v580.18l237.117-2.162v-614.174c0-333.334-93.573-457.298-345.585-457.298-151.472 0-217.057 44.925-281.322 98.98l-16.696 14.173H1047.88V721.441ZM240.24 0c132.493 0 240.24 107.748 240.24 240.24 0 132.493-107.747 240.24-240.24 240.24C107.748 480.48 0 372.733 0 240.24 0 107.748 107.748 0 240.24 0Zm0 120.12c-66.186 0-120.12 53.934-120.12 120.12s53.934 120.12 120.12 120.12 120.12-53.934 120.12-120.12-53.934-120.12-120.12-120.12Z" fillRule="evenodd"></path> </g></svg>                        </a>
                    </div>
                </div>
            </div>
           <ContactUs isOpen={openContactModal} onClose={()=>handleCloseContactModal()} onConfirm={(data) => handlePost(data)}/>
        </footer>

    );
}
export default Footer;