import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

const CustomAccordion = styled(Accordion)(({ theme }) => {
    return {
        borderRadius: '10px',
        boxShadow: 'none',
        marginBottom: '10px',
        border: `1px solid ${theme.palette.divider}`,
        '&::before': {
            display: 'none',
        },
    };
});

const CustomAccordionSummary  = styled(AccordionSummary )(({ theme }) => {
    return {
        borderRadius: '10px',
        boxShadow: 'none',
        border: 'none !important',
        color: '#334155'
    };
});

const CustomAccordionDetails  = styled(AccordionDetails )(({ theme }) => {
    return {
        borderRadius: '10px',
        boxShadow: 'none',
        border: 'none !important',
        color: '#64748b'
    };
});

export {CustomAccordion, CustomAccordionSummary, CustomAccordionDetails};