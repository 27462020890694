import React, {createContext, useContext, useMemo, useState} from "react";

const LayoutContext = createContext();

const LayoutContextProvider = ({children}) => {
    const [header, setHeader_] = useState({title: '', subtitle: '', chatId: -1, exerciseId: -1, userId: -1});
    const [sidebarSelectedMenuItem, setSidebarSelectedMenuItem_] = useState('');
    const setHeader = (title, subtitle, chatId = -1, exerciseId =  -1, userId = -1) => {
        setHeader_({title: title, subtitle: subtitle, chatId: chatId, exerciseId: exerciseId, userId: userId});
    }
    const setSidebarSelectedMenuItem = (menuTitle) => {
        setSidebarSelectedMenuItem_(menuTitle);
    }
    const [loader, setLoader] = useState(false);

    const contextValue = useMemo(
        () => ({
            header,
            loader,
            sidebarSelectedMenuItem,
            setHeader,
            setLoader,
            setSidebarSelectedMenuItem
        }),
        [header, loader, sidebarSelectedMenuItem]
    );

    return (
        <LayoutContext.Provider value={contextValue}>
            {children}
        </LayoutContext.Provider>
    );
};

export const useLayoutContext = () => {
    return useContext(LayoutContext);
};
export default LayoutContextProvider;
