import {useEffect} from "react";

import {useLayoutContext} from "../../layout/layoutProvider";
import {useLocation, useOutletContext} from "react-router-dom";
import {openCloseSidebarPanel} from "../../utils/helpers";
import ChatSidebarMenu from "../chat/components/chatSidebarMenu";
import UserListSidebarMenu from "./components/userListSidebarMenu";

const UserListPage = () => {
    const location = useLocation()
    const {setLoader, setSidebarSelectedMenuItem} = useLayoutContext();
    const user = useOutletContext().user;
    const course = useOutletContext().course;

    function updateMenu() {
        if (location.pathname.includes('user_list')) {
            setSidebarSelectedMenuItem('user_list');
        }
    }

    useEffect(() => {
        setLoader(false);
        updateMenu();
        openCloseSidebarPanel(true);
    }, [location]);
    useEffect(() => {
        const handleClickOutside = (event) => {
          const sidebar = document.getElementById('page-sidebar-panel');
          const toggleButton = document.getElementById('sidebar-toggle-btn');
          if (sidebar && !sidebar.contains(event.target) && !toggleButton.contains(event.target)) {
            openCloseSidebarPanel(false);
          }
        };
    
        window.addEventListener('click', handleClickOutside);
    
        return () => {
          window.removeEventListener('click', handleClickOutside);
        };
      }, [location]);
    
    return (
        <UserListSidebarMenu user={user} course={course}/>
    );
}

export default UserListPage;