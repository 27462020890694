import { Link } from "react-router-dom";
import { useLayoutContext } from "../layoutProvider";
import { sidebarMenuColors } from "../../utils/constants";

let currentColor = "";

const MessageItem = (props) => {
  const { sidebarSelectedMenuItem, setSidebarSelectedMenuItem } =
    useLayoutContext();
  if (sidebarSelectedMenuItem === "messages") {
    currentColor = sidebarMenuColors.selected;
  } else {
    currentColor = sidebarMenuColors.unselected;
  }
  return (
    <div className="is-scrollbar-hidden flex flex-col space-y-4 overflow-y-auto pt-6">
      <Link
        to={"/messages"}
        onClick={(event) => setSidebarSelectedMenuItem("message")}
        data-tooltip="Messages"
        data-placement="right"
        className="tooltip-main-sidebar flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
      >
        <svg
          className="h-7 w-7"
          viewBox="0 0 620 620"
          fill={currentColor}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill={currentColor}
            fillOpacity="1"
            d="M512,481.384c0.013,7.251-5.848,13.125-13.099,13.138l-485.382,0.669c-0.013,0-0.013,0-0.026,0
	c-3.462,0-6.792-1.377-9.257-3.829c-2.452-2.465-3.842-5.796-3.855-9.27L0,197.849c0-0.079,0.013-0.144,0.013-0.223
	c0.066-4.091,2.019-8.103,5.651-10.608l73.65-50.901V82.148c0-7.238,5.861-13.112,13.112-13.112h83.943l72.208-49.905
	c4.497-3.108,10.45-3.094,14.935,0.026l71.749,49.878h84.311c7.251,0,13.112,5.874,13.112,13.112v54.612l73.296,50.953
	c2.622,1.823,4.366,4.432,5.153,7.277c0.302,0.669,0.472,1.836,0.472,2.609L512,481.384z M485.737,455.291l-0.315-233.054
	L327.146,332.274L485.737,455.291z M432.685,226.944l41.906-29.122l-41.906-29.122V226.944z M51.871,468.915l407.916-0.564
	L256.197,310.416L51.871,468.915z M406.461,245.183V95.26H105.539v150.5l101.12,69.9l41.5-32.19
	c4.773-3.711,11.289-3.58,15.892-0.131c0.066,0.052,0.118,0.079,0.184,0.131l41.198,31.954L406.461,245.183z M222.512,69.036h66.806
	L256.013,45.88L222.512,69.036z M26.578,455.344l158.341-122.821L26.25,222.867L26.578,455.344z M79.315,227.639v-59.647
	l-43.152,29.83L79.315,227.639z"
          />
          <polygon
            fill={currentColor}
            fillOpacity="0.3"
            points="485.422,222.237 485.737,455.291 327.146,332.274 "
          />
          <polygon
            fill={currentColor}
            fillOpacity="0.3"
            points="474.591,197.823 432.685,226.944 432.685,168.701 "
          />
          <polygon
            fill={currentColor}
            fillOpacity="0.3"
            points="459.788,468.351 51.871,468.915 256.197,310.416 "
          />
          <path
            fill={currentColor}
            fillOpacity="0"
            d="M406.461,95.26v149.923l-101.028,70.241l-41.198-31.954c-0.066-0.052-0.118-0.079-0.184-0.131
	c-4.602-3.448-11.119-3.58-15.892,0.131l-41.5,32.19l-101.12-69.9V95.26H406.461z M357.881,160.82
	c0-7.238-5.874-13.112-13.112-13.112H167.231c-7.238,0-13.112,5.874-13.112,13.112c0,7.238,5.874,13.112,13.112,13.112h177.537
	C352.007,173.932,357.881,168.058,357.881,160.82z M357.881,229.003c0-7.238-5.874-13.112-13.112-13.112H167.231
	c-7.238,0-13.112,5.874-13.112,13.112s5.874,13.112,13.112,13.112h177.537C352.007,242.115,357.881,236.241,357.881,229.003z"
          />
          <g>
            <path
              fill={currentColor}
              fillOpacity="1"
              d="M344.769,147.708c7.238,0,13.112,5.874,13.112,13.112c0,7.238-5.874,13.112-13.112,13.112H167.231
		c-7.238,0-13.112-5.874-13.112-13.112c0-7.238,5.874-13.112,13.112-13.112H344.769z"
            />
            <path
              fill={currentColor}
              fillOpacity="1"
              d="M344.769,215.891c7.238,0,13.112,5.874,13.112,13.112s-5.874,13.112-13.112,13.112H167.231
		c-7.238,0-13.112-5.874-13.112-13.112s5.874-13.112,13.112-13.112H344.769z"
            />
          </g>
          <polygon
            fill={currentColor}
            fillOpacity="0.3"
            points="289.318,69.036 222.512,69.036 256.013,45.88 "
          />
          <polygon
            fill={currentColor}
            fillOpacity="0.3"
            points="184.919,332.523 26.578,455.344 26.25,222.867 "
          />
          <polygon
            fill={currentColor}
            fillOpacity="0.3"
            points="79.315,167.993 79.315,227.639 36.163,197.823 "
          />
        </svg>
      </Link>
    </div>
  );
};

export default MessageItem;
