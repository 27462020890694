const ACTIVE_CLASS = "is-active";
const LIST_MENU_HEADER_CLASS = "listmenu";
const LIST_MENU_ITEM_CLASS = "listmenu-item";

export default class ListMenu {
    aciveListMenu = null;

    constructor(wrapper, onChange = () => {}) {
        if (wrapper instanceof HTMLElement) {
            this.wrapper = wrapper;
        } else {
            this.wrapper = document.querySelector(wrapper);
        }

        if (!this.wrapper) {
            throw new TypeError("Error: List Menu Wrapper not defined");
        }

        this.listMenus = this.wrapper.querySelectorAll(`.${LIST_MENU_HEADER_CLASS}`);
        this.listMenuItems = this.wrapper.querySelectorAll(`.${LIST_MENU_ITEM_CLASS}`);

        if (this.listMenus.length === 0) {
            throw new TypeError("Error: List Menu items not defined");
        }

        this.aciveListMenu = this.wrapper.dataset.activeListMenu;

        this.showListMenu(this.aciveListMenu);

        if (this.listMenuItems) {
            this.listMenuItems.forEach((node) => {
                const nodeID = node.dataset.menuId;

                node.addEventListener("click", () => {
                    if (nodeID != this.aciveListMenu) {
                        this.showListMenu(nodeID);
                        onChange(nodeID);
                    }
                });
            });
        }
    }

    showListMenu(id) {
        if (this.listMenuItems) {
            this.listMenuItems.forEach((node) => {
                const activeClass = node.dataset.activeClass;
                const defaultClass = node.dataset.defaultClass;

                if (node.dataset.menuId == id) {
                    if (defaultClass)
                        node.classList.remove(...defaultClass.trim().split(" "));
                    if (activeClass)
                        node.classList.add(...activeClass.trim().split(" "), ACTIVE_CLASS);
                } else {
                    if (activeClass)
                        node.classList.remove(...activeClass.trim().split(" "), ACTIVE_CLASS);
                    if (defaultClass)
                        node.classList.add(...defaultClass.trim().split(" "));
                }
            });
        }

        this.aciveListMenu = id;
    }
}
