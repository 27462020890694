import {Link} from 'react-router-dom'
import {useLayoutContext} from "../layoutProvider";
import {sidebarMenuColors} from "../../utils/constants";

let currentColor = "";

const AboutItem = () => {
    const {sidebarSelectedMenuItem, setSidebarSelectedMenuItem} = useLayoutContext();
    if (sidebarSelectedMenuItem === 'about') {
        currentColor = sidebarMenuColors.selected;
    } else {
        currentColor = sidebarMenuColors.unselected;
    }

    return (
        <div className="is-scrollbar-hidden flex flex-col space-y-4 overflow-y-auto pt-6">
            <Link to="/about" onClick={(event) => setSidebarSelectedMenuItem('about')}
                  data-tooltip="About Us"
                  data-placement="right"
                  className="tooltip-main-sidebar flex h-11 w-11 items-center justify-center rounded-lg outline-none transition-colors duration-200 hover:bg-primary/20 focus:bg-primary/20 active:bg-primary/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25">
                <svg version="1" xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" viewBox="0 0 50 50"
                     fill="currentColor">
                    <circle fill={currentColor} fillOpacity="0.3" cx="24" cy="24" r="21"/>
                    <rect x="22" y="22" fill={currentColor} fillOpacity="1" width="4" height="11"/>
                    <circle fill={currentColor} fillOpacity="1" cx="24" cy="16.5" r="2.5"/>
                </svg>
            </Link>
        </div>);
}

export default AboutItem;