import { Dialog } from "@mui/material";
import { useState } from "react";
import * as yup from 'yup';

const ContactUs = ({onClose, isOpen, onConfirm}) =>{

    const [messageItem, setMessageItem] = useState({email:"", message:""})
    const [errorsList, setErrorsList] = useState([]);

    const postMessage = async () => {
        const schema = yup.object().shape({
            messageItem: yup.object().shape({
              email: yup.string().required("Emial is required !").email("Format is incorrect !"),
              message: yup.string().required("Message is required !"),
            }),
          
        });
        try {
            await schema.validate( {messageItem} , { abortEarly: false });           
           onConfirm(messageItem);
           onClose();
        } catch (err) {
            setErrorsList(err.inner);
        }
    }
    const keyUpHandler = (event) => {
        if (event.key === 'Enter') {
            postMessage();
        }
        return true;
    };
 return(
    <Dialog
    open={isOpen}
    onClose={()=> onClose()}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    <div
        className="modal-content relative flex w-full max-w-lg origin-top flex-col overflow-hidden bg-white dark:bg-navy-700">
        <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
            <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                Contact us
            </h3>
            <button
                onClick={() => onClose()}
                className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4.5 w-4.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </button>
        </div>
        <form className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5"
            onKeyUp={(event) => keyUpHandler(event)}>  
            
            <div className="mt-4 space-y-4">
                <label className="block">
                    <span>Email:</span>
                    <input
                        className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Your Email"
                        name="email"
                        value={messageItem.subject}
                        onChange={(e) => setMessageItem(messageItem=> ({
                          ...messageItem,
                          email:e.target.value
                        }))}
                        type={"email"}
                    />
                    <span className="text-error-focus">{errorsList.filter((name) => name.path === "messageItem.email")?.[0]?.errors?.[0]}</span>
                </label>
                <label className="block">
                    <span>Message:</span>
                    <input
                        className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                        placeholder="Your Message"
                        name="message"
                        value={messageItem.subject}
                        onChange={(e) => setMessageItem(messageItem=> ({
                          ...messageItem,
                          message:e.target.value
                        }))}
                        type={"text"}
                    />
                    <span className="text-error-focus">{errorsList.filter((name) => name.path === "messageItem.message")?.[0]?.errors?.[0]}</span>
                </label>

            </div>
            <hr className="mt-4"/>
            <span className="mt-3 flex cursor-pointer flex-wrap justify-between  text-black">
              <p className="text-sm">You can contact us via </p>
                <a 
                    className="text-jclogo-400 text-sm transition-colors hover:text-jclogo-600 dark:text-jclogo-100 dark:hover:text-jclogo-400"
                    href="mailto:journicraft.com@gmail.com">
                    journicraft.com@gmail.com
                </a>
             </span> 
        </form>
        <div className="space-x-2 p-3 text-right">
            <button
                className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                onClick={() => onClose()}
            >
                Cancel
            </button>
            <button
                onClick={()=>postMessage()}
                className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
            >
                Send
            </button>
        </div>
    </div>  
    </Dialog>
                

 )
}
export default ContactUs;