import Cookies from "js-cookie";

const cookie_object2string = (obj) => {
    if (obj === undefined || obj === null) return null;

    var result = JSON.stringify(obj);
    return result;
}

const cookie_string2object = (str) => {
    if (str === undefined || str === null) return null;

    str = str.split(', ');
    var result = {};
    for (var i = 0; i < str.length; i++) {
        var cur = str[i].split('=');
        result[cur[0]] = cur[1];
    }
    return result;
}

const get_cookies_token = () => {
    return Cookies.get("token");
}

const get_cookies_user = () => {
    return cookie_string2object(Cookies.get("user"));
}

const set_cookies_token = (token) => {
    Cookies.set("token", token, { expires: 7, path: '/' });
}

const set_cookies_user = (user) => {
    Cookies.set("user", cookie_object2string(abbr_user(user)), { expires: 7, path: '/' });
}

const set_cookies_course = (course) => {
    Cookies.set("course", cookie_object2string(course), { expires: 7, path: '/' });
}

const abbr_user = (user) => {
    var abbr_user = user;
    delete abbr_user.studentCourses;
    delete abbr_user.teacherCourses;

    return abbr_user;
}

export {
    cookie_object2string, cookie_string2object,
    get_cookies_token, get_cookies_user,
    set_cookies_user, set_cookies_course, set_cookies_token
}